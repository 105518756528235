import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { HomeIcon, ArrowPathIcon, PencilSquareIcon, TrashIcon } from "@heroicons/react/24/solid";
import { ArrowDownIcon, ArrowUpIcon } from "@heroicons/react/16/solid";
import { useNavigate } from "react-router-dom";
import Pagination from "../components/pagination";
import Footer from "../components/footer";
import MySQLUrl from "../DBConfig";
import Breadcrumb from "../components/breadcrumb";
import ImagePopup from "../components/image-popup";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {confirmAlert} from "react-confirm-alert";

const ManagePartnerGallery = () => {
    const navigate = useNavigate();
    const [User, setUser] = useState([]);
    const [partnerGalleryData, setPartnerGalleryData] = useState([]);
    const [currentTime, setCurrentTime] = useState(new Date());
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize] = useState(10);
    const [sortField, setSortField] = useState(null);
    const [sortOrder, setSortOrder] = useState('asc');
    const [selectedImage, setSelectedImage] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            const storedUser = localStorage.getItem('user');

            if (storedUser) {
                const parsedUser = JSON.parse(storedUser);
                setUser(parsedUser);

                try {
                    const response = await axios.get(`${MySQLUrl}/gallery/partner-images/all`);
                    const allPartnerGallery = response.data;

                    setPartnerGalleryData(allPartnerGallery);
                } catch (error) {
                    console.error('Error fetching data:', error);

                    // Retry after 5 seconds
                    setTimeout(() => {
                        fetchData();
                    }, 5000);
                }
            }
        };

        // Initial data fetch
        fetchData();
    }, []);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentTime(new Date());
        }, 1000); // Update every second

        // Cleanup the interval on component unmount
        return () => clearInterval(intervalId);
    }, []);


    const formattedTime = currentTime.toLocaleTimeString();

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const handleSort = (field) => {
        if (sortField === field) {
            setSortOrder((prevOrder) => (prevOrder === 'asc' ? 'desc' : 'asc'));
        } else {
            setSortField(field);
            setSortOrder('asc');
        }
    };

    const renderSortIcon = (field) => {
        if (sortField === field) {
            return sortOrder === 'asc' ? (
                <ArrowUpIcon className="h-4 w-4 text-gray-500 inline-block ml-1" />
            ) : (
                <ArrowDownIcon className="h-4 w-4 text-gray-500 inline-block ml-1" />
            );
        }
        return null;
    };



    const handleReload = async () => {
        try {
            const response = await axios.get(`${MySQLUrl}/gallery/partner-images/all`);
            const allPartnerGallery = response.data;
            setPartnerGalleryData(allPartnerGallery);
            toast.success("Reload Successful");
        } catch (error) {
            console.error('Error reloading partner Gallery data:', error);
        }
    };

    const goToNewPartnerGallery = () => {
        navigate('/partner-gallery/new');
        console.log('Button clicked!');
    };



    const breadcrumbItems = [
        { label: <HomeIcon className="h-4 w-5" />, url: '/' },
        { label: 'Projects', url: '/partner-gallery' },
    ];

    const formatDate = (timestamp) => {
        const date = new Date(timestamp);
        return date.toLocaleString(); // You can customize the format using options
    };


    const handleImageClick = (imageUrl) => {
        setSelectedImage(imageUrl);
    };

    const closeImagePopup = () => {
        setSelectedImage(null);
    };

    const handleDeletePartner = async (partnerId) => {
        confirmAlert({
            title: 'Confirm Deletion',
            message: 'Are you sure you want to delete this project?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {
                        try {
                            await axios.delete(`${MySQLUrl}/gallery/delete/${partnerId}`);
                            await handleReload();
                            toast.success('Project listing deleted successfully');
                        } catch (error) {
                            console.error('Error deleting project listing:', error);
                            toast.error('Error deleting project listing');
                        }
                    }
                },
                {
                    label: 'No',
                    onClick: () => {} // Do nothing if "No" is clicked
                }
            ]
        });
    };


    return (
        <>

            <ToastContainer
                position="top-right" // Adjust position as needed
                autoClose={5000} // Close after 5 seconds
                hideProgressBar={false} // Show progress bar
                newestOnTop={false} // Show newest toast at the top
                closeOnClick // Close toast on click
                rtl={false} // Right to left
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />

        <div>
            <div className="mx-4 text-neutral-800 overflow-y-auto">
                <div className="grid grid-cols-12 col-span-12 gap-4 xxl:col-span-9">
                    <div className="col-span-12 mt-0">
                        <div className="grid gap-2 grid-cols-1 lg:grid-cols-2">
                            <div className="mt-3">
                                <Breadcrumb items={breadcrumbItems} />
                            </div>
                            <div className="mt-3 relative">
                                {User.length === 0 ? (
                                    <div className="absolute right-0">
                                        Welcome, Loading...
                                    </div>
                                ) : (
                                    <div className="absolute right-0 capitalize">
                                        Welcome, <strong>{User.username} </strong> | {formattedTime}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="col-span-12 mt-0">
                        <div className="flex items-center">
                            <h2 className="mr-5 text-lg font-medium truncate ">Projects</h2>
                        </div>
                    </div>
                    <div className="col-span-12 mt-1">

                        <div className="grid gap-2 grid-cols-1 lg:grid-cols-1 mb-2">
                            <div className="bg-white p-4 shadow-lg rounded-lg">
                                <div className="grid gap-2 grid-cols-1 lg:grid-cols-2 mb-2">
                                    <h1 className="font-bold text-base">Manage Projects</h1>
                                    <div className="relative">
                                        <div className="absolute right-0">
                                            {partnerGalleryData.length === 0 ? (
                                                <button
                                                    onClick={handleReload}
                                                    className={`flex items-center p-2 mt-2 bg-blue-200 text-gray-400 rounded`}
                                                    disabled
                                                >
                                                    <div className="flex items-center">
                                                        <div className="loader me-2"></div>
                                                        Reloading
                                                    </div>
                                                </button>
                                            ) : (
                                                <button
                                                    onClick={handleReload}
                                                    className={`absolute right-0 flex items-center p-2 mt-2 bg-blue-800 hover:bg-blue-400 text-white rounded`}
                                                >
                                                    <div className="flex items-center">
                                                        <ArrowPathIcon className="h-5 w-5 mr-2" />
                                                        Reload
                                                    </div>
                                                </button>
                                            )}
                                        </div>
                                        <div className="absolute right-1/4">
                                            <button
                                                onClick={goToNewPartnerGallery}
                                                className={`flex items-center p-2 mt-2 bg-green-800 text-white rounded hover:bg-green-400`}
                                            >
                                                New Project
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                {/* Filter and Search */}
                                <div className="flex items-center space-x-2">
                                    <label htmlFor="searchInput" className="font-medium">Search:</label>
                                    <input
                                        type="text"
                                        id="searchInput"
                                        className="appearance-none border rounded"
                                        value={searchTerm}
                                        onChange={(e) => setSearchTerm(e.target.value)}
                                    />
                                </div>
                                <div className="mt-4">
                                    <div className="flex flex-col">
                                        <div className="-my-2 overflow-x-auto overflow-y-auto">
                                            <div className="py-2 align-middle inline-block min-w-full">
                                                <div
                                                    className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg bg-white">
                                                    <div className="relative max-h-[45vh] overflow-y-auto">
                                                        <table className="min-w-full bg-white border border-gray-300 text-neutral-800">
                                                                <thead>
                                                                <tr>
                                                                    <th className="py-2 px-4 border-b whitespace-nowrap"
                                                                        onClick={() => handleSort('id')}>
                                                                        No. {renderSortIcon('id')}
                                                                    </th>
                                                                    <th className="py-2 px-4 border-b whitespace-nowrap"
                                                                        onClick={() => handleSort('title')}>
                                                                        Title {renderSortIcon('title')}
                                                                    </th>
                                                                    <th className="py-2 px-4 border-b whitespace-nowrap"
                                                                        onClick={() => handleSort('description')}>
                                                                        Description {renderSortIcon('description')}
                                                                    </th>
                                                                    <th className="py-2 px-4 border-b whitespace-nowrap">
                                                                        Cover
                                                                    </th>
                                                                    <th className="py-2 px-4 border-b whitespace-nowrap"
                                                                        onClick={() => handleSort('createdAt')}>
                                                                        Created Date {renderSortIcon('createdAt')}
                                                                    </th>
                                                                    <th className="py-2 px-4 border-b whitespace-nowrap"
                                                                        onClick={() => handleSort('updatedAt')}>
                                                                        Updated Date {renderSortIcon('updatedAt')}
                                                                    </th>

                                                                    <th className="py-2 px-4 border-b whitespace-nowrap">Actions</th>
                                                                </tr>
                                                                </thead>
                                                                <tbody>

                                                                {partnerGalleryData.map(partner => (
                                                                    <tr key={partner.id}>
                                                                        <td className="py-2 px-4 border-b whitespace-nowrap">{partner.id}</td>
                                                                        <td className="py-2 px-4 border-b whitespace-nowrap">{partner.title}</td>
                                                                        <td className="py-2 px-4 border-b whitespace-nowrap">{partner.description}</td>
                                                                        <td className="py-2 px-4 border-b whitespace-nowrap">
                                                                            <img onClick={() => handleImageClick(`${MySQLUrl}/${partner.directoryPath.replace(/\\/g, '/')}/${partner.coverImageWebp}`)} src={`${MySQLUrl}/${partner.directoryPath.replace(/\\/g, '/')}/${partner.coverImageWebp}`} className="max-h-[100px]" alt={`Partner Image`} />

                                                                            {/*<img src={`${basePath}/${blog.image.replace(/\\/g, '/')}`} alt={`Blog Image`}/>*/}

                                                                        </td>
                                                                        <td className="py-2 px-4 border-b whitespace-nowrap">{formatDate(partner.createdAt)}</td>
                                                                        <td className="py-2 px-4 border-b whitespace-nowrap">{formatDate(partner.updatedAt)}</td>


                                                                        <td className="py-2 px-4 border-b whitespace-nowrap">
                                                                            <div>
                                                                                {/*<button type="button" className="text-yellow-800 hover:text-yellow-400 mr-2">*/}
                                                                                {/*    <PencilSquareIcon className="h-5 text-current"/>*/}
                                                                                {/*</button>*/}
                                                                                <button
                                                                                    type="button"
                                                                                    onClick={() => handleDeletePartner(partner.id)}
                                                                                    className="text-red-800 hover:text-red-400 mr-2">
                                                                                    <TrashIcon className="h-5 text-current"/>
                                                                                </button>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                ))}

                                                                </tbody>

                                                            </table>

                                                        {/* Results Count */}
                                                        <div className="text-sm text-gray-600 mt-2">
                                                            {/*{sortedPartnerGalleryData.length === 0 ? 'No results found.' : `Showing ${((currentPage - 1) * pageSize) + 1}-${Math.min(currentPage * pageSize, sortedPartnerGalleryData.length)} of ${sortedPartnerGalleryData.length} results.`}*/}
                                                        </div>
                                                        <div className="mb-4">
                                                            {/* Pagination */}
                                                            <Pagination
                                                                currentPage={currentPage}
                                                                pageSize={pageSize}
                                                                totalItems={setPartnerGalleryData.length}
                                                                onPageChange={handlePageChange}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {selectedImage && <ImagePopup imageUrl={selectedImage} onClose={closeImagePopup} />}
        </div>

        </>
    );
};

export default ManagePartnerGallery;

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { HomeIcon, ArrowPathIcon, PencilSquareIcon, TrashIcon } from "@heroicons/react/24/solid";
import { ArrowDownIcon, ArrowUpIcon } from "@heroicons/react/16/solid";
import { useNavigate } from "react-router-dom";
import Pagination from "../components/pagination";
import Footer from "../components/footer";
import MySQLUrl from "../DBConfig";
import Breadcrumb from "../components/breadcrumb";
import {toast, ToastContainer} from "react-toastify";
import { confirmAlert } from 'react-confirm-alert'; // Import react-confirm-alert module
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css file

const ManageVacancies = () => {
    const navigate = useNavigate();
    const [User, setUser] = useState([]);
    const [vacancyData, setVacancyData] = useState([]);
    const [currentTime, setCurrentTime] = useState(new Date());
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize] = useState(10);
    const [sortField, setSortField] = useState(null);
    const [sortOrder, setSortOrder] = useState('asc');
    const [totalVacancies, setTotalVacancies] = useState(0);

    useEffect(() => {
        const fetchData = async () => {
            const storedUser = localStorage.getItem('user');

            if (storedUser) {
                const parsedUser = JSON.parse(storedUser);
                setUser(parsedUser);

                try {
                    const response = await axios.get(`${MySQLUrl}/vacancy?page=${currentPage}&pageSize=${pageSize}`);
                    const allVacancies = response.data;

                    setVacancyData(allVacancies);
                } catch (error) {
                    console.error('Error fetching data:', error);

                    // Retry after 5 seconds
                    setTimeout(() => {
                        fetchData();
                    }, 5000);
                }
            }
        };

        // Initial data fetch
        fetchData();
    }, []);

    const fetchTotalVacancies = async () => {
        try {
            const response = await axios.get(`${MySQLUrl}/vacancy/getCount`);
            const totalCount = response.data.vacancyCount; // Assuming the response contains totalCount
            setTotalVacancies(totalCount);
        } catch (error) {
            console.error('Error fetching total count of vacancies:', error);
        }
    };

    useEffect(() => {
        fetchTotalVacancies();
    }, []);


    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentTime(new Date());
        }, 1000); // Update every second

        // Cleanup the interval on component unmount
        return () => clearInterval(intervalId);
    }, []);

    const filteredVacancyData = vacancyData
        .filter(vacancy => (
            (!searchTerm || vacancy.vacancy_name.toLowerCase().includes(searchTerm.toLowerCase()))
        ));

    const formattedTime = currentTime.toLocaleTimeString();

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };



    const handleSort = (field) => {
        if (sortField === field) {
            setSortOrder((prevOrder) => (prevOrder === 'asc' ? 'desc' : 'asc'));
        } else {
            setSortField(field);
            setSortOrder('asc');
        }
    };

    const renderSortIcon = (field) => {
        if (sortField === field) {
            return sortOrder === 'asc' ? (
                <ArrowUpIcon className="h-4 w-4 text-gray-500 inline-block ml-1" />
            ) : (
                <ArrowDownIcon className="h-4 w-4 text-gray-500 inline-block ml-1" />
            );
        }
        return null;
    };

    const sortedVacancyData = [...filteredVacancyData].sort((a, b) => {
        if (sortField) {
            const aValue = a[sortField];
            const bValue = b[sortField];

            if (typeof aValue === 'string' && typeof bValue === 'string') {
                return sortOrder === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
            }

            // Add additional checks for other data types if necessary
            return sortOrder === 'asc' ? aValue - bValue : bValue - aValue;
        }

        return 0;
    });

    const handleReload = async () => {
        try {
            const response = await axios.get(`${MySQLUrl}/vacancy?page=${currentPage}&pageSize=${pageSize}`);
            const allVacancies = response.data;
            setVacancyData(allVacancies);
            toast.success('Reloaded successfully');
        } catch (error) {
            console.error('Error reloading vacancy data:', error);
            toast.error('Reloading failed');
        }
    };

    const goToNewVacancy = () => {
        navigate('/new-vacancy');
        console.log('Button clicked!');
    };

    const handleDeleteVacancy = async (vacancyId) => {
        confirmAlert({
            title: 'Confirm Deletion',
            message: 'Are you sure you want to delete this vacancy?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {
                        try {
                            await axios.delete(`${MySQLUrl}/vacancy/delete/${vacancyId}`);
                            await handleReload();
                            toast.success('Vacancy listing deleted successfully');
                        } catch (error) {
                            console.error('Error deleting vacancy listing:', error);
                            toast.error('Error deleting vacancy listing');
                        }
                    }
                },
                {
                    label: 'No',
                    onClick: () => {} // Do nothing if "No" is clicked
                }
            ]
        });
    };



    const breadcrumbItems = [
        { label: <HomeIcon className="h-4 w-5" />, url: '/' },
        { label: 'Vacancies', url: '/vacancy' },
    ];


    return (
        <>
            <ToastContainer
                position="top-right" // Adjust position as needed
                autoClose={1000} // Close after 2 seconds
                hideProgressBar={false} // Show progress bar
                newestOnTop={false} // Show newest toast at the top
                closeOnClick // Close toast on click
                rtl={false} // Right to left
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />

        <div >
            <div className="mx-4 text-neutral-800 overflow-y-auto">
                <div className="grid grid-cols-12 col-span-12 gap-4 xxl:col-span-9">
                    <div className="col-span-12 mt-0">
                        <div className="grid gap-2 grid-cols-1 lg:grid-cols-2">
                            <div className="mt-3">
                                <Breadcrumb items={breadcrumbItems} />
                            </div>
                            <div className="mt-3 relative">
                                {User.length === 0 ? (
                                    <div className="absolute right-0">
                                        Welcome, Loading...
                                    </div>
                                ) : (
                                    <div className="absolute right-0 capitalize">
                                        Welcome, <strong>{User.username} </strong> | {formattedTime}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="col-span-12 mt-0">
                        <div className="flex items-center">
                            <h2 className="mr-5 text-lg font-medium truncate ">Vacancies</h2>
                        </div>
                    </div>
                    <div className="col-span-12 mt-1">

            <div className="grid gap-2 grid-cols-1 lg:grid-cols-1 mb-2 ">
                <div className="bg-white p-4 shadow-lg rounded-lg">
                    <div className="grid gap-2 grid-cols-1 lg:grid-cols-2 mb-2">
                        <h1 className="font-bold text-base">Manage Vacancies</h1>
                        <div className="relative">
                            <div className="absolute right-0">
                                {vacancyData.length === 0 ? (
                                    <button
                                        onClick={handleReload}
                                        className={`flex items-center p-2 mt-2 bg-blue-200 text-gray-400 rounded`}
                                        disabled
                                    >
                                        <div className="flex items-center">
                                            <div className="loader me-2"></div>
                                            Reloading
                                        </div>
                                    </button>
                                ) : (
                                    <button
                                        onClick={handleReload}
                                        className={`absolute right-0 flex items-center p-2 mt-2 bg-blue-800 hover:bg-blue-400 text-white rounded`}
                                    >
                                        <div className="flex items-center">
                                            <ArrowPathIcon className="h-5 w-5 mr-2" />
                                            Reload
                                        </div>
                                    </button>
                                )}
                            </div>
                            <div className="absolute right-1/4">
                                <button
                                    onClick={goToNewVacancy}
                                    className={`flex items-center p-2 mt-2 bg-green-800 text-white rounded hover:bg-green-400`}
                                >
                                    New Vacancy
                                </button>
                            </div>
                        </div>
                    </div>
                    {/* Filter and Search */}
                    <div className="flex items-center space-x-2">
                        <label htmlFor="searchInput" className="font-medium">Search:</label>
                        <input
                            type="text"
                            id="searchInput"
                            className="appearance-none border rounded"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    </div>
                    <div className="mt-4">
                        <div className="flex flex-col">
                            <div className="-my-2 overflow-x-auto overflow-y-auto">
                                <div className="py-2 align-middle inline-block min-w-full">
                                    <div
                                        className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg bg-white">
                                        <div className="relative max-h-[40vh] overflow-y-auto">
                                            {filteredVacancyData.length === 0 ? (
                                                <div className="flex flex-col items-center justify-center">
                                                    <div className="loader"></div>
                                                    <p>Loading...</p>
                                                </div>
                                            ) : (
                            <table className="min-w-full bg-white border border-gray-300 text-neutral-800 ">
                                <thead>
                                <tr>
                                    <th className="py-2 px-4 border-b whitespace-nowrap"
                                        onClick={() => handleSort('id')}>
                                        No. {renderSortIcon('id')}
                                    </th>
                                    <th className="py-2 px-4 border-b whitespace-nowrap"
                                        onClick={() => handleSort('vacancy_name')}>
                                        Vacancy {renderSortIcon('vacancy_name')}
                                    </th>
                                    <th className="py-2 px-4 border-b whitespace-nowrap"
                                        onClick={() => handleSort('vacancy_home')}>
                                        Company Name {renderSortIcon('vacancy_home')}
                                    </th>
                                    <th className="py-2 px-4 border-b whitespace-nowrap"
                                        onClick={() => handleSort('country')}>
                                        Country {renderSortIcon('country')}
                                    </th>
                                    <th className="py-2 px-4 border-b whitespace-nowrap"
                                        onClick={() => handleSort('age_range')}>
                                        Age Range {renderSortIcon('age_range')}
                                    </th>
                                    <th className="py-2 px-4 border-b whitespace-nowrap"
                                        onClick={() => handleSort('price')}>
                                        Salary (LKR/USD/...) {renderSortIcon('price')}
                                    </th>

                                    <th className="py-2 px-4 border-b whitespace-nowrap">Actions</th>
                                </tr>
                                </thead>
                                <tbody>
                                {sortedVacancyData.map(vacancy => (
                                    <tr key={vacancy.id}>
                                        <td className="py-2 px-4 border-b whitespace-nowrap">{vacancy.id}</td>
                                        <td className="py-2 px-4 border-b whitespace-nowrap">{vacancy.vacancy_name}</td>
                                        <td className="py-2 px-4 border-b whitespace-nowrap">{vacancy.company_home}</td>
                                        <td className="py-2 px-4 border-b whitespace-nowrap">{vacancy.country}</td>
                                        <td className="py-2 px-4 border-b whitespace-nowrap">{vacancy.age_range}</td>
                                        <td className="py-2 px-4 border-b whitespace-nowrap">{vacancy.price}</td>


                                        <td className="py-2 px-4 border-b whitespace-nowrap">
                                            <div>
                                                {/*<button type="button" className="text-yellow-800 hover:text-yellow-400 mr-2">*/}
                                                {/*    <PencilSquareIcon className="h-5 text-current"/>*/}
                                                {/*</button>*/}
                                                <button type="button"
                                                        onClick={() => handleDeleteVacancy(vacancy.id)}
                                                        className="text-red-800 hover:text-red-400 mr-2">
                                                    <TrashIcon className="h-5 text-current"/>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                                </tbody>

                            </table>
                                            )}
                            {/* Results Count */}
                            <div className="text-sm text-gray-600 mt-2">
                                {totalVacancies === 0 ? 'No results found.' : `Showing ${((currentPage - 1) * pageSize) + 1}-${Math.min(currentPage * pageSize, totalVacancies)} of ${totalVacancies} results.`}
                            </div>
                            <div className="mb-4">
                                {/* Pagination */}
                                <Pagination
                                    currentPage={currentPage}
                                    pageSize={pageSize}
                                    totalItems={totalVacancies}
                                    onPageChange={handlePageChange}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
                    </div>
                </div>
            </div>
            </div>
                    </div>
                </div>
            </div>

        </div>
        </>

                        );
};

export default ManageVacancies;

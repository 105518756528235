import React, { useEffect, useState } from 'react';
import Breadcrumb from '../components/breadcrumb';
import Footer from '../components/footer';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import MySQLUrl from '../DBConfig';
import { HomeIcon } from '@heroicons/react/24/solid';
import * as XLSX from 'xlsx';
import {toast, ToastContainer} from "react-toastify";
import { confirmAlert } from 'react-confirm-alert'; // Import react-confirm-alert module
import 'react-confirm-alert/src/react-confirm-alert.css';

const SendSms = () => {
    const navigate = useNavigate();
    const [User, setUser] = useState([]);
    const [WalletBalance, setWalletBalance] = useState([]);
    const [currentTime, setCurrentTime] = useState(new Date());
    const [formData, setFormData] = useState({
        mobile: '',
        message: '',
        smsToken: localStorage.getItem('smsToken') || '',
    });
    const [uploadedNumbers, setUploadedNumbers] = useState([]);

    const [apiUserData, setApiUserData] = useState(() => {
        const smsUserData = localStorage.getItem('smsUserData');
        return smsUserData ? JSON.parse(smsUserData) : {};
    });

// Now you can directly access properties of apiUserData
    const userData = apiUserData; // This will contain the parsed user data



    useEffect(() => {
        const fetchBalance = async () => {
            // Retrieve user data and SMS token from local storage
            const storedUser = localStorage.getItem('user');
            const smsToken = formData.smsToken ;

            // If both user data and SMS token are present
            if (storedUser && smsToken) {
                // Parse the user data
                const parsedUser = JSON.parse(storedUser);
                // Set the user state with parsed user data
                setUser(parsedUser);

                // Send a POST request to the server to fetch balance
                const response = await axios.get(`${MySQLUrl}/sms/balance`);

                // Assuming the response contains the string "1|0.5"
                const responseData = response.data;

                // Split the response data to extract the value
                const parts = responseData.split("|");
                const extractedValue = parts[1]; // This will give you "0.5"


                // Log the response from the server
                console.log('Response from the server:', extractedValue);
                // Set the wallet balance state with the data received from the server
                setWalletBalance(extractedValue);
            } else {
                // If either user data or SMS token is missing, navigate to the login page
                navigate('/login');
            }
        };

        // Initial data fetch when the component mounts
        fetchBalance();
    }, []);



    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentTime(new Date());
        }, 1000); // Update every second
        // Cleanup the interval on component unmount
        return () => clearInterval(intervalId);
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleFileUpload = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onload = (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: 'array' });
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];
            const excelData = XLSX.utils.sheet_to_json(sheet);
            const numbers = excelData.map((row) => row.mobile);
            setFormData((prevData) => ({
                ...prevData,
                mobile: numbers.join(','), // No separator needed
            }));
        };
        reader.readAsArrayBuffer(file);
    };




    const handleFormSubmit = async (e) => {
        e.preventDefault();
        try {
            if (uploadedNumbers.length > 0 || formData.mobile.trim() !== '') {
                const response = await axios.post(`${MySQLUrl}/sms/new`, { ...formData });
                console.log('Response from the server:', response.data);
                if (response.data.error) {
                    // Handle the error returned from the server
                    toast.error(response.data.message || 'An error occurred while sending SMS.');
                } else {
                    const remainingBalance = response.data.data.walletBalance;
                    toast.success(`Message sent successfully. Available balance is LKR ${remainingBalance}`);

                    setFormData({
                        mobile: '',
                        message: '',
                    })
                }
            } else {
                console.error('No mobile numbers provided');
                toast.error('No mobile numbers provided');
            }
        } catch (error) {
            console.error('An error occurred while submitting the form:', error);
            toast.error('An error occurred while submitting the form.');
        }
    };





    const breadcrumbItems = [
        { label: <HomeIcon className="h-4 w-5" />, url: '/' },
        { label: 'SMS', url: '/sms/history' },
        { label: 'New SMS', url: '/sms/new' },
    ];


    const goToSMSHistory = () => {
        navigate('/sms/history');
        console.log('Button clicked!');
    };

    return (
        <>
            <ToastContainer
                position="top-right" // Adjust position as needed
                autoClose={5000} // Close after 2 seconds
                hideProgressBar={false} // Show progress bar
                newestOnTop={false} // Show newest toast at the top
                closeOnClick // Close toast on click
                rtl={false} // Right to left
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        <div>
            <div className="mx-4 text-neutral-800 overflow-y-auto">
                <div className="grid grid-cols-12 col-span-12 gap-4 xxl:col-span-9">
                    <div className="col-span-12 mt-0">
                        <div className="grid gap-2 grid-cols-1 lg:grid-cols-2">
                            <div className="mt-3">
                                <Breadcrumb items={breadcrumbItems} />
                            </div>
                            <div className="mt-3 relative">
                                {User.length === 0 ? (
                                    <div className="absolute right-0">Welcome, Loading...</div>
                                ) : (
                                    <div className="absolute right-0 capitalize">
                                        Welcome, <strong>{User.username} </strong> |{' '}
                                        {currentTime.toLocaleTimeString()}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="col-span-8 mt-1">
                        <div className="grid gap-2 grid-cols-1 lg:grid-cols-1">
                            <div className="bg-white p-4 shadow-lg rounded-lg h-[30rem] overflow-y-auto">
                                <div className="flex justify-end items-start mt-2">
                                    <button
                                        onClick={goToSMSHistory}
                                        className="bg-purple-950 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded"
                                            >View SMS History</button>
                                </div>
                                <form onSubmit={handleFormSubmit}>
                                    <div className="mb-4">
                                        <label htmlFor="mobile" className="block text-sm font-medium text-gray-600">
                                            Mobile
                                        </label>
                                        <input
                                            type="text"
                                            id="mobile"
                                            name="mobile"
                                            placeholder="Eg: 0712345678 or upload the excel sheet with the correct template"
                                            value={formData.mobile}
                                            onChange={handleInputChange}
                                            className="mt-1 p-2 w-full border rounded-md"
                                        />
                                    </div>
                                    <div className="mb-4">
                                        <label htmlFor="message" className="block text-sm font-medium text-gray-600">
                                            Message
                                        </label>
                                        <textarea
                                            id="message"
                                            name="message"
                                            placeholder="Welcome to Tee Gee Agency (Pvt) Ltd"
                                            value={formData.message}
                                            onChange={handleInputChange}
                                            className="mt-1 p-2 w-full border rounded-md"
                                        />
                                    </div>
                                    <div className="mb-4">
                                        <label htmlFor="excelFile" className="block text-sm font-medium text-gray-600">
                                            Upload Excel File
                                        </label>
                                        <input
                                            type="file"
                                            id="excelFile"
                                            name="excelFile"
                                            accept=".xls,.xlsx"
                                            onChange={handleFileUpload}
                                            className="mt-1 p-2 w-full border rounded-md"
                                        />
                                    </div>
                                    <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded-md">
                                        Submit
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>


                    <div className="col-span-4 mt-1">
                        <div className="grid gap-2 grid-cols-1 lg:grid-cols-1">
                            <div className="bg-stone-100 text-slate-300 pt-0 p-4 px-0 shadow-lg rounded-lg h-[30rem] overflow-y-auto text-right">

                                <div className="block px-4 bg-gray-800 py-4">
                                    <h3>Wallet Balance</h3>
                                    <div className="flex justify-end items-start mt-2">
                                        <span className="font-black text-2xl mr-2">LKR</span>
                                        <span className="font-black text-5xl">{WalletBalance}</span>
                                    </div>
                                </div>

                                <div className="block mt-5 px-4 text-gray-800 ">
                                    <div className="flex items-start mb-4">
                                         <span className="font-bold text-xl">SMS API Details</span>
                                    </div>
                                    <div className="flex items-center justify-between mt-2">
                                        <span className="font-medium text-md mr-2">Default SMS Mask:</span>
                                        <span className="font-medium text-md">{userData.defaultMask}</span>
                                    </div>
                                    <div className="flex items-center justify-between mt-2">
                                        <span className="font-medium text-md mr-2">Email:</span>
                                        <span className="font-medium text-md">{userData.email}</span>
                                    </div>
                                    <div className="flex  items-center justify-between mt-2">
                                        <span className="font-medium text-md mr-2">Mobile:</span>
                                        <span className="font-medium text-md">{userData.mobile}</span>
                                    </div>
                                    <div className="flex  items-center justify-between mt-2">
                                        <span className="font-medium text-md mr-2">Address:</span>
                                        <span className="font-medium text-md">{userData.address}</span>
                                    </div>
                                </div>


                            </div>
                        </div>

                    </div>

                </div>
            </div>

        </div>
        </>
    );
};

export default SendSms;

import React from 'react';

const Pagination = ({ currentPage, pageSize, totalItems, onPageChange }) => {
    const totalPages = Math.ceil(totalItems / pageSize);

    const range = (start, end) => {
        return Array.from({ length: end - start + 1 }, (_, i) => start + i);
    };

    const pageNumbers = range(1, totalPages);

    return (
        <div className="mt-4 flex justify-center items-center space-x-2">
            <button
                onClick={() => onPageChange(currentPage - 1)}
                disabled={currentPage === 1}
                className="bg-gray-200 p-2 rounded"
            >
                Previous
            </button>

            {pageNumbers.map((number) => (
                <button
                    key={number}
                    onClick={() => onPageChange(number)}
                    className={`p-2 rounded ${number === currentPage ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
                >
                    {number}
                </button>
            ))}

            <button
                onClick={() => onPageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
                className="bg-gray-200 p-2 rounded"
            >
                Next
            </button>
        </div>
    );
};

export default Pagination;

import React, { useEffect, useState } from 'react';
import axios from 'axios';

import '../css/style.css';
import Breadcrumb from '../components/breadcrumb';
import { EyeIcon, HomeIcon } from "@heroicons/react/24/solid";
import Footer from "../components/footer";
import MySQLUrl from "../DBConfig";
import { useNavigate } from "react-router-dom";
import {toast, ToastContainer} from "react-toastify";
import { confirmAlert } from 'react-confirm-alert';

const Home = () => {
    const navigate = useNavigate();
    const [User, setUser] = useState([]);
    const [loginCount, setLoginCount] = useState(null);
    const [blogData, setBlogData] = useState([]);
    const [totalBlogs, setTotalBlogs] = useState(0);
    const [applicantData, setApplicantData] = useState([]);
    const [totalApplicants, setTotalApplicants] = useState(0);
    const [vacancyCount, setVacancyCount] = useState(null);
    const [currentTime, setCurrentTime] = useState(new Date());
    const [accessToken, setAccessToken] = useState('');



    useEffect(() => {
        const fetchData = async () => {
            try {
                const storedUser = localStorage.getItem('user');

                if (storedUser) {


                    const parsedUser = JSON.parse(storedUser);
                    setUser(parsedUser)


                    const accessToken = localStorage.getItem('accessToken');
                    setAccessToken(accessToken);

                    const response = await axios.get(`${MySQLUrl}/user/login-count/${parsedUser.id}`);
                    const loginCount = response.data;

                    setLoginCount(loginCount);

                    try {
                        const config = {
                            headers: {
                                Authorization: `Bearer ${accessToken}`
                            }
                        };


                        const response = await axios.get(`${MySQLUrl}/applicant`, config);
                        const allApplicants = response.data;

                        // Get the total count of all applicants
                        const totalApplicants = allApplicants.length;
                        setTotalApplicants(totalApplicants);

                        // Sort the applicants by id in descending order and take the first 15
                        const recentApplicants = allApplicants.sort((a, b) => b.id - a.id).slice(0, 15);

                        setApplicantData(recentApplicants);
                    } catch (error) {
                        console.error('Error fetching data:', error);

                    }

                    try {
                        const response = await axios.get(`${MySQLUrl}/blog`);
                        const blogsWithSubcategories = await Promise.all(
                            response.data.map(async (blog) => {
                                const subcategoryResponse = await axios.get(`${MySQLUrl}/blog/sub-category/${blog.subcategory_id}`);
                                const subcategoryName = subcategoryResponse.data.subcategory_name;
                                return { ...blog, subcategoryName };
                            })
                        );

                        const totalBlogs = blogsWithSubcategories.length;
                        setTotalBlogs(totalBlogs);

                        const recentBlogs = blogsWithSubcategories.sort((a, b) => b.blog_id - a.blog_id).slice(0, 15);

                        setBlogData(recentBlogs);
                    } catch (error) {
                        console.error('Error fetching blog data:', error);

                    }


                } else{
                    navigate('/login');
                }
            } catch (error) {
                console.error('Error fetching data:', error);


            }
        };

        fetchData();

    }, []);




    useEffect(() => {
        const fetchVacancyCount = async () => {
            try {
                const response = await axios.get(`${MySQLUrl}/vacancy/getCount`);
                setVacancyCount(response.data.vacancyCount);
            } catch (error) {
                console.error('Error fetching vacancy count:', error);

            }
        };

        fetchVacancyCount();
    }, []);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentTime(new Date());
        }, 1000); // Update every second

        // Cleanup the interval on component unmount
        return () => clearInterval(intervalId);
    }, []);

    const formattedTime = currentTime.toLocaleTimeString();

    const goToBlog = () => {
        navigate('/blog');
        console.log('Button clicked!');
    };

    const goToApplicants = () => {
        navigate('/applicant');
        console.log('Button clicked!');
    };

    const breadcrumbItems = [
        { label: <HomeIcon className="h-4 w-5" />, url: '/' },
        { label: 'Dashboard', url: '/' },
    ];

    // Function to truncate a string to a specified number of words
    const truncateWords = (text, maxWords) => {
        const words = text.split(' ');
        const truncatedWords = words.slice(0, maxWords);
        return truncatedWords.join(' ') + (words.length > maxWords ? '...' : ''); // Add ellipsis if truncated
    };

    const truncateCharacters = (text, maxCharacters) => {
        if (text.length <= maxCharacters) {
            return text;
        }

        const truncatedText = text.slice(0, maxCharacters);
        return truncatedText + '...';
    };

    return (

        <>

            <ToastContainer
                position="top-right" // Adjust position as needed
                autoClose={1000} // Close after 2 seconds
                hideProgressBar={false} // Show progress bar
                newestOnTop={false} // Show newest toast at the top
                closeOnClick // Close toast on click
                rtl={false} // Right to left
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />


            <div className="bg-neutral-200">

            <div className="mx-4 text-neutral-800 overflow-y-auto">
                <div className="grid grid-cols-12 col-span-12 gap-3 xxl:col-span-9">

                    <div className="col-span-12 mt-0">
                        <div className="grid gap-2 grid-cols-1 lg:grid-cols-2">

                            <div className="mt-3">
                                <Breadcrumb items={breadcrumbItems} />
                            </div>

                            <div className="mt-3 relative">
                                {User.length === 0 ? (
                                    <div className="absolute right-0">
                                        Welcome, Loading...
                                    </div>
                                    ):
                                    (
                                    <div className="absolute right-0 capitalize">
                                        Welcome, <strong>{User.username} </strong> | {formattedTime}
                                    </div>)}
                            </div>


                        </div>
                    </div>

                    <div className="col-span-12 mt-0">
                        <div className="flex items-center">
                            <h2 className="mr-5 text-lg font-medium truncate ">Dashboard</h2>
                        </div>
                    </div>

                    <div className="col-span-12 mt-0">
                        <div className="grid gap-2 grid-cols-1 lg:grid-cols-2">

                            {/* Statistics */}
                            <div className="bg-white shadow-lg p-4">
                                <div className="px-2 py-2 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-2">
                                    <div className="grid grid-cols-2 gap-8">

                                        <div className="text-center">
                                            {applicantData.length === 0 ? (
                                                <div className="flex flex-col items-center justify-center">
                                                    <div className="loader"></div>
                                                </div>
                                            ) : (
                                                <h6 className="text-4xl font-bold lg:text-5xl xl:text-4xl">{totalApplicants}</h6>
                                            )}
                                            <p className="text-sm font-medium tracking-widest text-gray-800 uppercase lg:text-base">
                                                Total Applicants
                                            </p>
                                        </div>


                                        <div className="text-center">
                                            {blogData.length === 0 ? (
                                                <div className="flex flex-col items-center justify-center">
                                                    <div className="loader"></div>
                                                </div>
                                            ) : (
                                                <h6 className="text-4xl font-bold lg:text-5xl xl:text-4xl">{totalBlogs}</h6>
                                            )}
                                            <p className="text-sm font-medium tracking-widest text-gray-800 uppercase lg:text-base">
                                                Total Blogs
                                            </p>
                                        </div>


                                        <div className="text-center">
                                            {loginCount === null ? (
                                                <div className="flex flex-col items-center justify-center">
                                                    <div className="loader"></div>
                                                </div>
                                            ) : (
                                                <h6 className="text-4xl font-bold lg:text-5xl xl:text-4xl">{loginCount.loginCount}</h6>
                                            )}
                                            <p className="text-sm font-medium tracking-widest text-gray-800 uppercase lg:text-base">
                                                Login Count
                                            </p>
                                        </div>


                                        <div className="text-center">
                                            {vacancyCount === null ? (
                                                <div className="flex flex-col items-center justify-center">
                                                    <div className="loader"></div>
                                                </div>
                                            ) : (
                                                <h6 className="text-4xl font-bold lg:text-5xl xl:text-4xl">{vacancyCount}</h6>
                                            )}
                                            <p className="text-sm font-medium tracking-widest text-gray-800 uppercase lg:text-base">
                                                VACANCIES POSTED
                                            </p>
                                        </div>
                                    </div>
                                </div>


                            </div>

                            {/* Applicants */}
                            <div className="bg-white shadow-lg">
                                <div className="bg-white p-4 shadow-lg rounded-lg">
                                    <div className="grid gap-2 grid-cols-1 lg:grid-cols-2">
                                        <h1 className="font-bold text-base">Recent Applicants</h1>

                                        <div className="relative">
                                            <button onClick={goToApplicants} className="absolute top-0 right-0 bg-purple-950 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded">
                                                Manage Applicants
                                            </button>
                                        </div>
                                    </div>

                                    <div className="mt-4 relative max-h-[150px] overflow-y-auto">
                                        {applicantData.length === 0 ? (
                                            <div className="flex flex-col items-center justify-center">
                                                <div className="loader"></div>
                                                <p>Loading...</p>
                                            </div>
                                        ) : (
                                            <table className="min-w-full bg-white border border-gray-300 text-neutral-800 table-auto">
                                                <thead>
                                                <tr>
                                                    <th className="py-2 px-4 border-b whitespace-nowrap"># Ref No. </th>
                                                    <th className="py-2 px-4 border-b whitespace-nowrap">Name</th>
                                                    <th className="py-2 px-4 border-b whitespace-nowrap">Applied Post</th>
                                                    <th className="py-2 px-4 border-b whitespace-nowrap">Passport No.</th>
                                                    <th className="py-2 px-4 border-b whitespace-nowrap">Passport Expiry</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {applicantData.map(applicant => {
                                                    const timestamp = applicant.ExpiryDate;
                                                    const formattedDateTime = new Date(timestamp).toLocaleString();

                                                    return (
                                                        <tr key={applicant.ref_no}>
                                                            <td className="py-2 px-4 border-b whitespace-nowrap">{applicant.ref_no}</td>
                                                            <td className="py-2 px-4 border-b whitespace-nowrap">{applicant.fullName}</td>
                                                            <td className="py-2 px-4 border-b whitespace-nowrap">{applicant.postApplied}</td>
                                                            <td className="py-2 px-4 border-b whitespace-nowrap">{applicant.passportNo}</td>
                                                            <td className="py-2 px-4 border-b whitespace-nowrap">{formattedDateTime}</td>
                                                        </tr>
                                                    );
                                                })}

                                                </tbody>
                                            </table>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-span-12 pt-0 mt-0">
                        <div className="grid gap-2 grid-cols-1 lg:grid-cols-1">
                            <div className="bg-white p-4 shadow-lg rounded-lg">
                                <div className="grid gap-2 grid-cols-1 lg:grid-cols-2">
                                    <h1 className="font-bold text-base">Recent Blogs</h1>

                                    <div className="relative">
                                        <button onClick={goToBlog} className="absolute top-0 right-0 bg-purple-950 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded">
                                            Manage Blogs
                                        </button>
                                    </div>
                                </div>

                                <div className="mt-4">
                                    <div className="flex flex-col">
                                        <div className="-my-2 overflow-x-auto overflow-y-auto">
                                            <div className="py-2 align-middle inline-block min-w-full">
                                                <div
                                                    className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg bg-white">
                                                    <div className="relative max-h-[140px] overflow-y-auto">
                                                        {blogData.length === 0 ? (
                                                            <div className="flex flex-col items-center justify-center">
                                                                <div className="loader"></div>
                                                                <p>Loading...</p>
                                                            </div>
                                                        ) : (

                                                            <table className="min-w-full bg-white border border-gray-300 text-neutral-800">
                                                                <thead>
                                                                <tr>
                                                                    <th className="py-2 px-4 border-b whitespace-nowrap">No. </th>
                                                                    <th className="py-2 px-4 border-b whitespace-nowrap">Title</th>
                                                                    <th className="py-2 px-4 border-b whitespace-nowrap">Sub Category</th>
                                                                    <th className="py-2 px-4 border-b whitespace-nowrap">Content</th>
                                                                    <th className="py-2 px-4 border-b whitespace-nowrap">Views</th>
                                                                </tr>
                                                                </thead>
                                                                <tbody>
                                                                {blogData.map(blog => (
                                                                    <tr key={blog.blog_id}>
                                                                        <td className="py-2 px-4 border-b whitespace-nowrap">{blog.blog_id}</td>
                                                                        <td className="py-2 px-4 border-b whitespace-nowrap">{blog.title}</td>
                                                                        <td className="py-2 px-4 border-b whitespace-nowrap">{blog.subcategoryName}</td>
                                                                        <td className="py-2 px-4 border-b whitespace-nowrap">{truncateCharacters(blog.content, 50)}</td>
                                                                        <td className="py-2 px-4 border-b flex items-center whitespace-nowrap">
                                                                            <EyeIcon className="h-4" />{blog.view_count}
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                                </tbody>

                                                            </table>
                                                        )}
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>
        </>
    );
};

export default Home;

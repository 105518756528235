import React, { useEffect, useState, useRef } from 'react';

import '../css/style.css';
import Breadcrumb from '../components/breadcrumb';
import { HomeIcon } from "@heroicons/react/24/solid";
import Footer from "../components/footer";

import axios from "axios";
import MySQLUrl from "../DBConfig";
import {useNavigate} from "react-router-dom";
import {Textarea} from "@material-tailwind/react";
import {toast, ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const NewBlogs = () => {
    const navigate = useNavigate();
    const [User, setUser] = useState([]);
    const [categoryData, setCategoryData] = useState([]);
    const [subCategoryData, setSubCategoryData] = useState([]);
    const [currentTime, setCurrentTime] = useState(new Date());
    const formattedTime = currentTime.toLocaleTimeString();
    const [selectedCategory, setSelectedCategory] = useState('');
    const [file, setFile] = useState(null);
    const [previewImage, setPreviewImage] = useState(null);


    useEffect(() => {
        const fetchData = async () => {
            const storedUser = localStorage.getItem('user');

            if (storedUser) {
                const parsedUser = JSON.parse(storedUser);
                setUser(parsedUser);

                try {
                    const response = await axios.get(`${MySQLUrl}/blog/categories`);
                    const allCategories = response.data;
                    setCategoryData(allCategories);
                } catch (error) {
                    console.error('Error fetching categories:', error);

                    // Retry after 5 seconds
                    setTimeout(() => {
                        fetchData();
                    }, 5000);
                }

            }else{
                navigate('/login');
            }
        };

        // Initial data fetch
        fetchData();
    }, []);


    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentTime(new Date());
        }, 1000); // Update every second

        // Cleanup the interval on component unmount
        return () => clearInterval(intervalId);
    }, []);


    const breadcrumbItems = [
        { label: <HomeIcon className="h-4 w-5" />, url: '/' },
        { label: 'Blogs', url: '/blog' },
        { label: 'New Blog', url: '/new_blog' },
    ];

    const [formData, setFormData] = useState({
        title: '',
        category: '',
        subcategory: '',
        content: '', // Initialize editor state
    });



    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();

        try {

            // Create a FormData object to handle file and other form data
            const formDataToSend = new FormData();
            formDataToSend.append('title', formData.title);
            // formDataToSend.append('content', formData.content); // Assuming you have content in your formData
            formDataToSend.append('subcategoryId', formData.subcategory);
            formDataToSend.append('userId', User.id);

            // Append the image file to FormData
            formDataToSend.append('blogImage', file);

            // Append rawContentState as a JSON string to FormData
            formDataToSend.append('content', formData.content);

            // Send a POST request to your API endpoint
            const response = await axios.post(`${MySQLUrl}/blog/new-post`, formDataToSend);

            toast.success('Blog posted successfully');
            console.log('Response from the server:', response.data);

            // Clear the form
            setFormData({
                title: '',
                category: '',
                subcategory: '',
                content: '',

            });

            setFile(null);
            setPreviewImage(null);

            // Optionally, you can redirect the user to a new page or show a success message
        } catch (error) {
            toast.error('Blog posting failed');
            console.error('Error submitting form:', error);
            // Handle error, show an error message, etc.
        }
    };


    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        setFile(selectedFile);

        // Generate a preview image URL
        const previewImageUrl = URL.createObjectURL(selectedFile);
        setPreviewImage(previewImageUrl);
    };

    const fetchSubcategoriesByCategory = async (categoryID) => {
        try {
            if (categoryID) {
                console.log('before sending axios request');
                const response = await axios.get(`${MySQLUrl}/blog/category/sub-category/${categoryID}`);
                console.log("Subcategory Response:", response.data);

                    setSubCategoryData(response.data);

            }
        } catch (error) {
            console.error('Error fetching subcategories:', error);
        }
    };




    const handleCategoryChange = async (e) => {
        const { value } = e.target;

        // Clear the current subcategory when a new category is selected
        setSubCategoryData([]);
        setSelectedCategory(value)
        console.log("Selected Category:", value);

        try {
            await fetchSubcategoriesByCategory(value);
        } catch (error) {
            console.error('Error fetching subcategories:', error);
        }
    };



    return (
        <>
            <ToastContainer
                position="top-right" // Adjust position as needed
                autoClose={1000} // Close after 2 seconds
                hideProgressBar={false} // Show progress bar
                newestOnTop={false} // Show newest toast at the top
                closeOnClick // Close toast on click
                rtl={false} // Right to left
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />

        <div>
            <div className="mx-4 text-neutral-800 overflow-y-auto">
                <div className="grid grid-cols-12 col-span-12 gap-4 xxl:col-span-9">
                    <div className="col-span-12 mt-0">
                        <div className="grid gap-2 grid-cols-1 lg:grid-cols-2">
                            <div className="mt-3">
                                <Breadcrumb items={breadcrumbItems} />
                            </div>
                            <div className="mt-3 relative">
                                {User.length === 0 ? (
                                    <div className="absolute right-0">
                                        Welcome, Loading...
                                    </div>
                                ) : (
                                    <div className="absolute right-0 capitalize">
                                        Welcome, <strong>{User.username} </strong> | {formattedTime}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="col-span-12 mt-0">
                        <div className="flex items-center">
                            <h2 className="mr-5 text-lg font-medium truncate ">New Blog</h2>
                        </div>
                    </div>
                    <div className="col-span-12 mt-1">
                        <div className="grid gap-2 grid-cols-1 lg:grid-cols-1">
                            <div className="bg-white p-4 shadow-lg rounded-lg h-[65vh] max-h-[65vh] overflow-y-auto">

                                {/* Form */}
                                <form onSubmit={handleFormSubmit}>
                                    <div className="mb-4 grid grid-cols-2 gap-4">
                                        <div>
                                            <label htmlFor="title" className="block text-sm font-medium text-gray-600">
                                                Title
                                            </label>
                                            <input
                                                type="text"
                                                id="title"
                                                name="title"
                                                value={formData.title}
                                                placeholder="Eg: Visiting Romania for business"
                                                onChange={handleInputChange}
                                                className="mt-1 p-2 w-full border rounded-md"
                                                required
                                            />

                                            {formData.title.length === 0 && (
                                                <p className="text-red-500 text-xs italic">Please fill out this field.</p>
                                            )}

                                            {formData.title.length >= 10 &&  (
                                                <p className="text-green-500 text-xs italic">Looks Good</p>
                                            )}
                                        </div>
                                        <div>
                                            <label htmlFor="category" className="block text-sm font-medium text-gray-600">
                                                Category
                                                {categoryData.length === 0 && <div className="loader mt-2"></div>}
                                            </label>
                                            {/* Replace the input with a select dropdown */}
                                            {categoryData.length > 0 && (
                                                <select
                                                    id="category"
                                                    name="category"
                                                    value={selectedCategory}
                                                    onChange={handleCategoryChange}
                                                    className="mt-1 p-2 w-full border rounded-md"
                                                    required
                                                >
                                                    <option value="" disabled>Select a category</option>
                                                    {categoryData.map((category) => (
                                                        <option key={category.category_id} value={category.category_id}>
                                                            {category.category_name}
                                                        </option>
                                                    ))}
                                                </select>
                                            )}
                                        </div>

                                    </div>


                                    {/* Replace the subcategory select with the following code */}
                                    <div className="mb-4">
                                        <label htmlFor="subcategory" className="block text-sm font-medium text-gray-600">
                                            Subcategory
                                            {subCategoryData.length === 0 && <div className="loader mt-2"></div>}
                                        </label>
                                        <select
                                            id="subcategory"
                                            name="subcategory"
                                            value={formData.subcategory}
                                            onChange={handleInputChange}
                                            className="mt-1 p-2 w-full border rounded-md"
                                            required
                                            disabled={subCategoryData.length === 0} // Disable select while loading
                                        >
                                            {subCategoryData.length === 0 ?
                                                (
                                                <option value="" disabled>First select the category</option>
                                                )
                                                :
                                                (
                                                <option value="" >Select a subcategory</option>
                                            )}
                                            {subCategoryData.map((subcategory) => (
                                                <option key={subcategory.subcategory_id} value={subcategory.subcategory_id}>
                                                    {subcategory.subcategory_name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>


                                    <div className="mb-4">
                                        <label htmlFor="content" className="block text-sm font-medium text-gray-600">
                                            Post Description
                                        </label>
                                        <Textarea
                                            id="content"
                                            name="content"
                                            placeholder="Eg: Last week our team has flown to Romania for business purposes..."
                                            value={formData.content}
                                            onChange={handleInputChange}
                                            required
                                        />
                                        {formData.content.length === 0 && (
                                            <p className="text-red-500 text-xs italic">Please fill out this field.</p>
                                        )}

                                        {formData.content.length >= 25 &&  (
                                            <p className="text-green-500 text-xs italic">Looks Good</p>
                                        )}
                                    </div>

                                    <div className="mb-4 grid grid-cols-1 md:grid-cols-2 md:gap-4">
                                        {/* File Input */}
                                        <div className="mb-4 md:mb-0">
                                            <label htmlFor="image" className="block text-sm font-medium text-gray-600">
                                                Upload Image
                                            </label>
                                            <input
                                                type="file"
                                                id="image"
                                                name="blogImage"

                                                onChange={handleFileChange}
                                                className="mt-1 p-2 w-full border rounded-md"
                                                accept="image/*" // Allow only image files
                                                required
                                            />
                                        </div>

                                        {/* Preview Image */}
                                        {previewImage && (
                                            <div>
                                                <label className="block text-sm font-medium text-gray-600">
                                                    Preview Image
                                                </label>
                                                <img src={previewImage} alt="Preview" className="border border-black p-4 w-auto h-[35vh]" />
                                            </div>
                                        )}
                                    </div>



                                    <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded-md">
                                        Submit
                                    </button>
                                </form>
                                {/* End of Form */}

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        </>
    );
};

export default NewBlogs;

import React from "react";
import {Link, useNavigate} from 'react-router-dom';
import {
    Card,
    Typography,
    List,
    ListItem,
    ListItemPrefix,
    ListItemSuffix,
    Chip,
    Accordion,
    AccordionHeader,
    AccordionBody,
} from "@material-tailwind/react";
import {
    PresentationChartBarIcon,
    UserCircleIcon,
    Cog6ToothIcon,
    InboxIcon,
    PowerIcon, NewspaperIcon, BriefcaseIcon, UsersIcon,
} from "@heroicons/react/24/solid";
import { ChevronRightIcon, ChevronDownIcon } from "@heroicons/react/24/outline";
import Settings from "../pages/Settings";

export default function Sidebar() {
    const navigate = useNavigate();

    const handleLogout = () => {

        localStorage.removeItem('accessToken');
        localStorage.removeItem('smsToken');
        localStorage.removeItem('smsUserData');
        localStorage.removeItem('user');
        // Redirect to the login page
        navigate('/login');
    };


    const [open, setOpen] = React.useState(0);

    const handleOpen = (value) => {
        setOpen(open === value ? 0 : value);
    };


    return (
        <aside className={"w-1/4 h-full  shadow-xl shadow-blue-gray-900/5 overflow-y-auto"}>
        <Card className="p-4">
            <List className="block">
                <Link to="/">
                <ListItem >

                    <ListItemPrefix>
                        <InboxIcon className="h-5 w-5 mr-3" />
                    </ListItemPrefix>
                    Dashboard


                </ListItem>
            </Link>

                <Accordion
                    open={open === 1}
                    icon={
                        <ChevronDownIcon
                            strokeWidth={2.5}
                            className={`mx-auto h-4 w-4 transition-transform ${open === 1 ? "rotate-180" : ""}`}
                        />
                    }
                >
                    <ListItem className="p-0" selected={open === 1}>
                        <AccordionHeader onClick={() => handleOpen(1)} className="border-b-0 p-3 justify-content-between">
                            <ListItemPrefix>
                                <NewspaperIcon className="h-5 w-5 mr-3" />
                            </ListItemPrefix>
                            <Typography color="blue-gray" className="mr-auto font-normal">
                                Blog
                            </Typography>
                        </AccordionHeader>
                    </ListItem>
                    <AccordionBody className="py-1 ">
                        <List className="p-0 block">
                            <div className="px-3">
                                <Link to="/blog">
                                    <ListItem>
                                        <ListItemPrefix>
                                            <ChevronRightIcon strokeWidth={3} className="h-3 w-5" />
                                        </ListItemPrefix>
                                        Manage Blogs
                                    </ListItem>
                                </Link>
                                <Link to="/new-blog">
                                    <ListItem>
                                        <ListItemPrefix>
                                            <ChevronRightIcon strokeWidth={3} className="h-3 w-5" />
                                        </ListItemPrefix>
                                        New Blogs
                                    </ListItem>
                                </Link>
                            </div>
                        </List>
                    </AccordionBody>
                </Accordion>

                <Accordion
                    open={open === 2}
                    icon={
                        <ChevronDownIcon
                            strokeWidth={2.5}
                            className={`mx-auto h-4 w-4 transition-transform ${open === 2 ? "rotate-180" : ""}`}
                        />
                    }
                >
                    <ListItem className="p-0" selected={open === 2}>
                        <AccordionHeader onClick={() => handleOpen(2)} className="border-b-0 p-3">
                            <ListItemPrefix>
                                <BriefcaseIcon className="h-5 w-5 mr-3" />
                            </ListItemPrefix>
                            <Typography color="blue-gray" className="mr-auto font-normal">
                                Vacancies
                            </Typography>
                        </AccordionHeader>
                    </ListItem>
                    <AccordionBody className="py-1 ">
                        <List className="p-0 block">
                            <div className="px-3">
                            <Link to="/vacancy">
                            <ListItem>
                                <ListItemPrefix>
                                    <ChevronRightIcon strokeWidth={3} className="h-3 w-5" />
                                </ListItemPrefix>
                                Manage Vacancies
                            </ListItem>
                            </Link>
                                <Link to="/new-vacancy">
                            <ListItem>
                                <ListItemPrefix>
                                    <ChevronRightIcon strokeWidth={3} className="h-3 w-5" />
                                </ListItemPrefix>
                                New Vacancies
                            </ListItem>
                                </Link>
                        </div>
                        </List>
                    </AccordionBody>
                </Accordion>

                <Accordion
                    open={open === 3}
                    icon={
                        <ChevronDownIcon
                            strokeWidth={2.5}
                            className={`mx-auto h-4 w-4 transition-transform ${open === 3 ? "rotate-180" : ""}`}
                        />
                    }
                >
                    <ListItem className="p-0" selected={open === 3}>
                        <AccordionHeader onClick={() => handleOpen(3)} className="border-b-0 p-3">
                            <ListItemPrefix>
                                <UsersIcon className="h-5 w-5 mr-3" />
                            </ListItemPrefix>
                            <Typography color="blue-gray" className="mr-auto font-normal">
                                Applicants
                            </Typography>
                        </AccordionHeader>
                    </ListItem>
                    <AccordionBody className="py-1 ">
                        <List className="p-0 block">
                            <div className="px-3">

                                <Link to="/applicant">
                            <ListItem>
                                <ListItemPrefix>
                                    <ChevronRightIcon strokeWidth={3} className="h-3 w-5" />
                                </ListItemPrefix>
                                Manage Applicants
                            </ListItem>
                                </Link>

                                <Link to="/new-applicants">
                            <ListItem>
                                <ListItemPrefix>
                                    <ChevronRightIcon strokeWidth={3} className="h-3 w-5" />
                                </ListItemPrefix>
                                New Applicants
                            </ListItem>
                            </Link>

                            </div>
                        </List>
                    </AccordionBody>
                </Accordion>


                <Accordion
                    open={open === 4}
                    icon={
                        <ChevronDownIcon
                            strokeWidth={2.5}
                            className={`mx-auto h-4 w-4 transition-transform ${open === 4 ? "rotate-180" : ""}`}
                        />
                    }
                >
                    <ListItem className="p-0" selected={open === 4}>
                        <AccordionHeader onClick={() => handleOpen(4)} className="border-b-0 p-3">
                            <ListItemPrefix>
                                <PresentationChartBarIcon className="h-5 w-5 mr-3" />
                            </ListItemPrefix>
                            <Typography color="blue-gray" className="mr-auto font-normal">
                                Projects
                            </Typography>
                        </AccordionHeader>
                    </ListItem>
                    <AccordionBody className="py-1 ">
                        <List className="p-0 block">
                            <div className="px-3">
                                <Link to={`/partner-gallery`}>
                            <ListItem>
                                <ListItemPrefix>
                                    <ChevronRightIcon strokeWidth={3} className="h-3 w-5" />
                                </ListItemPrefix>
                                Manage Album
                            </ListItem>
                                </Link>
                                <Link to={`/partner-gallery/new`}>
                            <ListItem>
                                <ListItemPrefix>
                                    <ChevronRightIcon strokeWidth={3} className="h-3 w-5" />
                                </ListItemPrefix>
                                New Album
                            </ListItem>
                                </Link>
                            </div>
                        </List>
                    </AccordionBody>

                </Accordion>


                <Accordion
                    open={open === 5}
                    icon={
                        <ChevronDownIcon
                            strokeWidth={2.5}
                            className={`mx-auto h-4 w-4 transition-transform ${open === 4 ? "rotate-180" : ""}`}
                        />
                    }
                >
                    <ListItem className="p-0" selected={open === 5}>
                        <AccordionHeader onClick={() => handleOpen(5)} className="border-b-0 p-3">
                            <ListItemPrefix>
                                <PresentationChartBarIcon className="h-5 w-5 mr-3" />
                            </ListItemPrefix>
                            <Typography color="blue-gray" className="mr-auto font-normal">
                                Workplace
                            </Typography>
                        </AccordionHeader>
                    </ListItem>
                    <AccordionBody className="py-1 ">
                        <List className="p-0 block">
                            <div className="px-3">
                                <Link to={`/workplace`}>
                                    <ListItem>
                                        <ListItemPrefix>
                                            <ChevronRightIcon strokeWidth={3} className="h-3 w-5" />
                                        </ListItemPrefix>
                                        Manage Workplace Gallery
                                    </ListItem>
                                </Link>
                                <Link to={`/workplace/new`}>
                                <ListItem>
                                    <ListItemPrefix>
                                        <ChevronRightIcon strokeWidth={3} className="h-3 w-5" />
                                    </ListItemPrefix>
                                    New Workplace Image
                                </ListItem>
                                 </Link>
                            </div>
                        </List>
                    </AccordionBody>

                </Accordion>



                <hr className="my-2 border-blue-gray-50" />
                <Link to={`/sms/new`}>
                <ListItem>
                    <ListItemPrefix>
                        <InboxIcon className="h-5 w-5 me-3" />
                    </ListItemPrefix>
                    SMS
                    <ListItemSuffix>
                        <Chip value="14" size="sm" variant="ghost" color="blue-gray" className="rounded-full" />
                    </ListItemSuffix>
                </ListItem>
                </Link>

                <ListItem>
                    <ListItemPrefix>
                        <UserCircleIcon className="h-5 w-5 me-3" />
                    </ListItemPrefix>
                    Profile
                </ListItem>

                <Link to="/settings">
                <ListItem>

                    <ListItemPrefix>
                        <Cog6ToothIcon className="h-5 w-5 me-3" />
                    </ListItemPrefix>
                    Settings

                </ListItem>
            </Link>


                <ListItem onClick={handleLogout} className="cursor-pointer">
                    <ListItemPrefix>
                        <PowerIcon className="h-5 w-5 me-3" />
                    </ListItemPrefix>
                    Log Out
                </ListItem>
            </List>
        </Card>
        </aside>
    );
}
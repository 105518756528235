import React, { Fragment, useEffect, useState } from 'react';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/react/24/outline';



import logo from '../images/logo/logo.webp';



import Sidebar from "./sidepane";
import {Link, useNavigate} from 'react-router-dom';

const navigation = [
    { name: 'Dashboard', href: '#', current: false },
    { name: 'Blog', href: '#', current: false },
    { name: 'Vacancies', href: '#', current: false },
    { name: 'Applicants', href: '#', current: false },
    { name: 'Projects', href: '#', current: false },
    { name: 'Workplace', href: '#', current: false },
    { name: 'SMS', href: '#', current: false },
];
const userNavigation = [
    { name: 'Your Profile', href: '#' },
    { name: 'Settings', href: '#' },
    { name: 'Sign out', href: '#' },
];

const handleLogoClick = () => {
    console.log('Logo clicked!');
};

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

export default function Header() {
    const navigate = useNavigate();
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Check for the presence of the JWT token
        const token = localStorage.getItem('accessToken');

        if (!token) {
            // Redirect to login if the token is not present
            localStorage.removeItem('smsToken');
            localStorage.removeItem('smsUserData');
            localStorage.removeItem('user');
            navigate('/login');
        } else {
            // If token exists, retrieve user data from localStorage
            const storedUser = localStorage.getItem('user');

            if (storedUser) {
                setUser(JSON.parse(storedUser));
            } else{
                localStorage.removeItem('accessToken');
                navigate('/login');
            }

            // Set loading to false once data is loaded
            setLoading(false);
        }
    }, []);

    const handleLogout = () => {

        localStorage.removeItem('accessToken');
        localStorage.removeItem('smsToken');
        localStorage.removeItem('smsUserData');
        localStorage.removeItem('user');
        // Redirect to the login page
        navigate('/login');
    };


    return (
        <>

                <Disclosure as="nav" className="bg-gray-800 px-8">
                    {({ open }) => (
                        <>


                            <div className="mx-auto ">

                                <div className="flex h-16 items-center justify-between">
                                    <div className="flex items-center">
                                        <div className="flex-shrink-0 bg-gray-100 py-3 px-2">
                                            <img
                                                className="h-25 w-36"
                                                src={logo}
                                                alt="Your Company"
                                                onClick={handleLogoClick}
                                            />
                                        </div>
                                        <div className="hidden md:block">
                                            <div className="ml-10 flex items-baseline space-x-4">
                                                {navigation.map((item) => (
                                                    <a
                                                        key={item.name}
                                                        href={item.href}
                                                        className={classNames(
                                                            item.current
                                                                ? 'bg-gray-900 text-white'
                                                                : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                                                            'rounded-md px-3 py-2 text-sm font-medium'
                                                        )}
                                                        aria-current={item.current ? 'page' : undefined}
                                                    >
                                                        {item.name}
                                                    </a>
                                                ))}
                                            </div>
                                        </div>
                                    </div>


                                    <div className="hidden md:block">
                                        <div className="ml-4 flex items-center md:ml-6">
                                            {/*<button*/}
                                            {/*    type="button"*/}
                                            {/*    className="relative rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"*/}
                                            {/*>*/}
                                            {/*    <span className="absolute -inset-1.5" />*/}
                                            {/*    <span className="sr-only">View notifications</span>*/}
                                            {/*    <BellIcon className="h-6 w-6" aria-hidden="true" />*/}
                                            {/*</button>*/}

                                            {/* Profile dropdown */}
                                            <Menu as="div" className="relative ml-3">
                                                {/*<div>*/}
                                                {/*    <Menu.Button className="relative flex max-w-xs items-center rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">*/}
                                                {/*        <span className="absolute -inset-1.5" />*/}
                                                {/*        <span className="sr-only">Open user menu</span>*/}
                                                {/*        <img className="h-8 w-8 rounded-full bg-white" src="https://cdn-icons-png.flaticon.com/512/1077/1077114.png" alt="" />*/}
                                                {/*    </Menu.Button>*/}
                                                {/*</div>*/}

                                                <div>
                                                    <Menu.Button className="relative flex max-w-xs items-center rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800  border-white border-3">
                                                        <span className="absolute -inset-1.5" />
                                                        <span className="sr-only">Open user menu</span>
                                                        <img className="h-8 w-8 rounded-full bg-white" src="https://cdn-icons-png.flaticon.com/512/1077/1077114.png" alt="" />
                                                    </Menu.Button>
                                                </div>


                                                <Transition
                                                    as={Fragment}
                                                    enter="transition ease-out duration-100"
                                                    enterFrom="transform opacity-0 scale-95"
                                                    enterTo="transform opacity-100 scale-100"
                                                    leave="transition ease-in duration-75"
                                                    leaveFrom="transform opacity-100 scale-100"
                                                    leaveTo="transform opacity-0 scale-95"
                                                >
                                                    <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">

                                                        <Menu.Item>
                                                            {loading ? (
                                                                <a>Loading...</a>
                                                            ) : (
                                                                <div>
                                                                    <div className="capitalize font-bold p-3 py-1">{user.username}</div>
                                                                    <div className="p-3 py-1">{user.email}</div>
                                                                    <hr/>
                                                                    <button
                                                                        className="p-3 py-1"
                                                                        onClick={handleLogout}>
                                                                        Sign Out
                                                                    </button>
                                                                </div>
                                                            )}

                                                        </Menu.Item>

                                                    </Menu.Items>
                                                </Transition>
                                            </Menu>
                                        </div>
                                    </div>
                                    <div className="-mr-2 flex md:hidden">
                                        {/* Mobile menu button */}
                                        <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md bg-gray-800 p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                                            <span className="absolute -inset-0.5" />
                                            <span className="sr-only">Open main menu</span>
                                            {open ? (
                                                <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                                            ) : (
                                                <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                                            )}
                                        </Disclosure.Button>
                                    </div>
                                </div>
                            </div>


                            <Disclosure.Panel className="md:hidden">
                                <div className="space-y-1 px-2 pb-3 pt-2 sm:px-3">
                                    {navigation.map((item) => (

                                        <Link to={`${item.href}`} key={item.name}>
                                        <Disclosure.Button
                                            as="a"
                                            className={classNames(
                                                item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                                                'block rounded-md px-3 py-2 text-base font-medium'
                                            )}
                                            aria-current={item.current ? 'page' : undefined}
                                        >
                                            {item.name}
                                        </Disclosure.Button>
                                        </Link>

                                    ))}
                                </div>
                                <div className="border-t border-gray-700 pb-3 pt-4">
                                    <div className="flex items-center px-5">
                                        <div className="flex-shrink-0">
                                            <img className="h-10 w-10 rounded-full" src="https://cdn-icons-png.flaticon.com/512/1077/1077114.png" alt="" />
                                        </div>
                                        <div className="ml-3">
                                            {/*<div className="text-base font-medium leading-none text-white">{user.username}</div>*/}
                                            {/*<div className="text-sm font-medium leading-none text-gray-400">{user.email}</div>*/}
                                        </div>
                                        <button
                                            type="button"
                                            className="relative ml-auto flex-shrink-0 rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                                        >
                                            <span className="absolute -inset-1.5" />
                                            <span className="sr-only">View notifications</span>
                                            <BellIcon className="h-6 w-6" aria-hidden="true" />
                                        </button>
                                    </div>
                                    <div className="mt-3 space-y-1 px-2">
                                        {userNavigation.map((item) => (
                                            <Disclosure.Button
                                                key={item.name}
                                                as="a"
                                                href={item.href}
                                                className="block rounded-md px-3 py-2 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white"
                                            >
                                                {item.name}
                                            </Disclosure.Button>
                                        ))}
                                    </div>
                                </div>
                            </Disclosure.Panel>

                        </>
                    )}
                </Disclosure>






        </>
    )
}

import React, { useEffect, useState } from 'react';
import '../css/style.css';
import Breadcrumb from '../components/breadcrumb';
import Footer from '../components/footer';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Textarea } from '@material-tailwind/react';
import MySQLUrl from "../DBConfig";
import { HomeIcon } from "@heroicons/react/24/solid";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const NewPartnerGallery = () => {
    const navigate = useNavigate();
    const [User, setUser] = useState([]);
    const [currentTime, setCurrentTime] = useState(new Date());
    const formattedTime = currentTime.toLocaleTimeString();
    const [coverImage, setCoverImage] = useState(null);
    const [previewCoverImage, setPreviewCoverImage] = useState(null);
    const [images, setImages] = useState([]);
    const [previewImages, setPreviewImages] = useState([]);

    const [formData, setFormData] = useState({
        title: '',
        description: '',
        coverImage: null,
        Images: [],
    });


    useEffect(() => {
        const fetchData = async () => {
            const storedUser = localStorage.getItem('user');

            if (storedUser) {
                const parsedUser = JSON.parse(storedUser);
                setUser(parsedUser);
            } else {
                navigate('/login');
            }
        };

        // Initial data fetch
        fetchData();
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleCoverImageChange = (e) => {
        const selectedCoverImage = e.target.files[0];
        setCoverImage(selectedCoverImage);

        // Generate a preview image URL
        const previewCoverImageUrl = URL.createObjectURL(selectedCoverImage);
        setPreviewCoverImage(previewCoverImageUrl);
    };

    const handleImagesChange = (e) => {
        const selectedImages = e.target.files;
        const imagesArray = Array.from(selectedImages);

        setImages(imagesArray);

        // Generate preview image URLs
        const previewImageUrls = imagesArray.map(image => URL.createObjectURL(image));
        setPreviewImages(previewImageUrls);
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();

        try {
            // Create FormData object
            const formDataToSend = new FormData();

            // Append other form data fields
            formDataToSend.append('title', formData.title);
            formDataToSend.append('description', formData.description);

            // Append cover image
            formDataToSend.append('coverImage', coverImage);

            // Append multiple images
            images.forEach((image, index) => {
                formDataToSend.append(`images`, image);
            });

            console.log(formDataToSend);

            // Send form data
            const response = await axios.post(`${MySQLUrl}/gallery/new-partner-images`, formDataToSend);
            console.log('Response from the server:', response.data);
            toast.success('Partner collection created successfully');
            // Clear form data and preview images after submission
            setFormData({
                title: '',
                description: '',
                coverImage: null,
                Images: [],
            });
            setCoverImage(null);
            setImages([]);
            setPreviewCoverImage(null);
            setPreviewImages([]);
        } catch (error) {
            toast.error('Error creating new partner collection');
            console.error('Error submitting form:', error);
        }
    };

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentTime(new Date());
        }, 1000); // Update every second

        // Cleanup the interval on component unmount
        return () => clearInterval(intervalId);
    }, []);


    const breadcrumbItems = [
        { label: <HomeIcon className="h-4 w-5" />, url: '/' },
        { label: 'Projects', url: '/partner-gallery' },
        { label: 'New Collection', url: '/partner-gallery/new' },
    ];

    return (
        <>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <div>
                <div className="mx-4 text-neutral-800 overflow-y-auto">
                    <div className="grid grid-cols-12 col-span-12 gap-4 xxl:col-span-9">
                        <div className="col-span-12 mt-0">
                            <div className="grid gap-2 grid-cols-1 lg:grid-cols-2">
                                <div className="mt-3">
                                    <Breadcrumb items={breadcrumbItems} />
                                </div>
                                <div className="mt-3 relative">
                                    {User.length === 0 ? (
                                        <div className="absolute right-0">
                                            Welcome, Loading...
                                        </div>
                                    ) : (
                                        <div className="absolute right-0 capitalize">
                                            Welcome, <strong>{User.username} </strong> | {formattedTime}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="col-span-12 mt-0">
                            <div className="flex items-center">
                                <h2 className="mr-5 text-lg font-medium truncate ">New Project</h2>
                            </div>
                        </div>
                        <div className="col-span-12 mt-1">
                            <div className="grid gap-2 grid-cols-1 lg:grid-cols-1">
                                <div className="bg-white p-4 shadow-lg rounded-lg h-[65vh] overflow-y-auto">
                                    <form onSubmit={handleFormSubmit}>
                                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                            <div className="mb-4">
                                                <label htmlFor="title" className="block text-sm font-medium text-gray-600">
                                                    Title
                                                </label>
                                                <input
                                                    type="text"
                                                    id="title"
                                                    name="title"
                                                    placeholder="Eg: CSR Project"
                                                    value={formData.title}
                                                    onChange={handleInputChange}
                                                    className="mt-1 p-2 w-full border rounded-md"
                                                    required
                                                />
                                            </div>
                                            <div className="mb-4">
                                                <label htmlFor="description" className="block text-sm font-medium text-gray-600">
                                                    Company Home
                                                </label>
                                                <input
                                                    type="text"
                                                    id="description"
                                                    name="description"
                                                    placeholder="Eg: Description of the partner project"
                                                    value={formData.company_home}
                                                    onChange={handleInputChange}
                                                    className="mt-1 p-2 w-full border rounded-md"
                                                />
                                            </div>
                                        </div>
                                        <div className="mb-4 grid grid-cols-1 md:grid-cols-2 md:gap-4">
                                            <div className="mb-4 md:mb-0">
                                                <label htmlFor="coverImage" className="block text-sm font-medium text-gray-600">
                                                    Upload Cover Image
                                                </label>
                                                <input
                                                    type="file"
                                                    id="coverImage"
                                                    name="coverImage"
                                                    onChange={handleCoverImageChange}
                                                    className="mt-1 p-2 w-full border rounded-md"
                                                    accept="image/*"
                                                    required
                                                />
                                            </div>
                                            {previewCoverImage && (
                                                <div>
                                                    <label className="block text-sm font-medium text-gray-600">
                                                        Preview Cover Image
                                                    </label>
                                                    <img src={previewCoverImage} alt="Preview Cover" className="border border-black p-4 w-auto h-[35vh]" />
                                                </div>
                                            )}
                                        </div>
                                        <div className="mb-4 grid grid-cols-1 md:grid-cols-2 md:gap-4">
                                            <div className="mb-4 md:mb-0">
                                                <label htmlFor="images" className="block text-sm font-medium text-gray-600">
                                                    Upload Other Images (Note: You can select multiple images)
                                                </label>
                                                <input
                                                    type="file"
                                                    id="images"
                                                    name="images"
                                                    onChange={handleImagesChange}
                                                    className="mt-1 p-2 w-full border rounded-md"
                                                    accept="image/*"
                                                    multiple
                                                    required
                                                />
                                            </div>
                                            {previewImages.map((previewImageUrl, index) => (
                                                <div key={index}>
                                                    <label className="block text-sm font-medium text-gray-600">
                                                        Preview Image {index + 1}
                                                    </label>
                                                    <img src={previewImageUrl} alt={`Preview ${index}`} className="border border-black p-4 w-auto h-[35vh]" />
                                                </div>
                                            ))}
                                        </div>
                                        <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded-md">
                                            Submit
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default NewPartnerGallery;

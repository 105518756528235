import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { HomeIcon, ArrowPathIcon, PencilSquareIcon, TrashIcon } from "@heroicons/react/24/solid";
import { Link, useNavigate } from "react-router-dom";
import MySQLUrl from "../DBConfig";
import { toast, ToastContainer } from "react-toastify";
import { confirmAlert } from 'react-confirm-alert'; // Import react-confirm-alert module
import 'react-confirm-alert/src/react-confirm-alert.css';
import { countries } from '../components/countries';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import Breadcrumb from "../components/breadcrumb";

const ManageApplicants = () => {
    const navigate = useNavigate();
    const [User, setUser] = useState([]);
    const [ApplicantData, setApplicantData] = useState([]);
    const [currentTime, setCurrentTime] = useState(new Date());
    const [totalApplicants, setTotalApplicants] = useState(0);
    const [accessToken, setAccessToken] = useState('');
    const [loading, setLoading] = useState(false);
    const [filters, setFilters] = useState({});
    const [globalFilter, setGlobalFilter] = useState('');
    const [VisibleCountries, setVisibleCountries] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState('');

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentTime(new Date());
        }, 1000); // Update every second

        // Cleanup the interval on component unmount
        return () => clearInterval(intervalId);
    }, []);

    const formattedTime = currentTime.toLocaleTimeString();

    useEffect(() => {
       const fetchData = async () => {
            const storedUser = localStorage.getItem('user');

            if (storedUser) {
                const parsedUser = JSON.parse(storedUser);
                setUser(parsedUser);

                const accessToken = localStorage.getItem('accessToken');

                if (!accessToken) {
                    localStorage.removeItem('smsToken');
                    localStorage.removeItem('smsUserData');
                    localStorage.removeItem('user');
                    navigate('/login');
                }

                setAccessToken(accessToken);

                const config = {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                };

                try {
                    let response;
                    if (ApplicantData.length === 0) {
                        response = await axios.get(`${MySQLUrl}/applicant`, config);
                    }

                    const allApplicants = response?.data ?? [];
                    setApplicantData(allApplicants);
                } catch (error) {
                    console.error('Error fetching data:', error);

                    // Retry after 5 seconds
                    setTimeout(() => {
                        fetchData();
                    }, 5000);
                }
            }
        };


        // Initial data fetch
        fetchData();
    }, []);




    useEffect(() => {
        fetchTotalApplicants();
    }, []);

    const fetchTotalApplicants = async () => {
        try {
            const response = await axios.get(`${MySQLUrl}/applicant/total-count`);
            const totalCount = response.data.totalCount; // Assuming the response contains totalCount
            setTotalApplicants(totalCount);
        } catch (error) {
            console.error('Error fetching total count of applicants:', error);
        }
    };


    // const formattedTime = currentTime.toLocaleTimeString();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${MySQLUrl}/applicant/visible-countries`);
                setVisibleCountries(response.data);
            } catch (error) {
                console.error('Error fetching visible countries:', error);
            }
        };
        fetchData();
    }, []);




    const handleReload = async () => {
        try {

            const config = {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            };

            const response = await axios.get(`${MySQLUrl}/applicant`,config);
            const allApplicants = response.data;
            setApplicantData(allApplicants);
            toast.success('Reloaded successfully');
        } catch (error) {
            console.error('Error reloading applicant data:', error);
            toast.error('Reloading failed');
        }
    };

    const handleCountryChange = (e) => {
        setSelectedCountry(e.target.value);
    };

    const filteredApplicants = selectedCountry ? ApplicantData.filter(applicant => {
        return VisibleCountries.some(country => country.applicant_ref === applicant.ref_no && country.country === selectedCountry);
    }) : ApplicantData;




    const handleDeleteApplicant = async (applicantId) => {
        confirmAlert({
            title: 'Confirm Deletion',
            message: 'Are you sure you want to delete this applicant?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {
                        try {

                            const config = {
                                headers: {
                                    Authorization: `Bearer ${accessToken}`
                                }
                            };

                            await axios.delete(`${MySQLUrl}/applicant/delete/${applicantId}`, config);
                            await handleReload();
                            toast.success('Applicant deleted successfully');
                        } catch (error) {
                            console.error('Error deleting applicant:', error);
                            toast.error('Error deleting applicant');
                        }
                    }
                },
                {
                    label: 'No',
                    onClick: () => {} // Do nothing if "No" is clicked
                }
            ]
        });
    };


    const handleEditApplicant = async (applicantId) => {
        confirmAlert({
            title: 'Confirm Editing Applicant',
            message: 'Are you sure you want to edit this applicant?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        navigate(`/applicant/edit/${applicantId}`);
                    }
                },
                {
                    label: 'No',
                    onClick: () => {} // Do nothing if "No" is clicked
                }
            ]
        });
    };

    const goToNewApplicant = () => {
        navigate('/new-applicants');
        console.log('Button clicked!');
    };



    const breadcrumbItems = [
        { label: <HomeIcon className="h-4 w-5" />, url: '/' },
        { label: 'Applicants', url: '/applicant' },
    ];


    const truncateWords = (text, maxWords) => {
        const words = text.split(' ');
        const truncatedWords = words.slice(0, maxWords);
        return truncatedWords.join(' ') + (words.length > maxWords ? '...' : '');
    };

    return (
        <>
            <ToastContainer
                position="top-right"
                autoClose={1000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />

            <div>
                <div className="mx-4 text-neutral-800 overflow-y-auto">
                    <div className="grid grid-cols-12 col-span-12 gap-4 xxl:col-span-9">
                        <div className="col-span-12 mt-0">
                            <div className="grid gap-2 grid-cols-1 lg:grid-cols-2">
                                <div className="mt-3">
                                    <Breadcrumb items={breadcrumbItems} />
                                </div>
                                <div className="mt-3 relative">
                                    {User.length === 0 ? (
                                        <div className="absolute right-0">
                                            Welcome, Loading...
                                        </div>
                                    ) : (
                                        <div className="absolute right-0 capitalize">
                                            Welcome, <strong>{User.username} </strong> | {formattedTime}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="col-span-12 mt-0">
                            <div className="flex items-center">
                                <h2 className="mr-5 text-lg font-medium truncate ">Manage Applicants</h2>
                            </div>
                        </div>

                        <div className="col-span-12 mt-0">
                            <div className="grid gap-2 grid-cols-1 lg:grid-cols-1 mb-2">
                                <div className="bg-white p-4 shadow-lg rounded-lg">

                                    {/* Search and filter controls */}
                                    <div className="flex items-center space-x-2 mb-4 justify-between">
                                        <div>
                                        <input
                                            type="text"
                                            placeholder="Search"
                                            className="appearance-none border rounded px-3 py-2"
                                            value={globalFilter}
                                            onChange={(e) => setGlobalFilter(e.target.value)}
                                        />
                                        <select value={selectedCountry}
                                                className="border rounded px-3 py-2"
                                                onChange={handleCountryChange}>
                                            <option value="">Select Country</option>
                                            {[...new Set(VisibleCountries.map(country => country.country))].map(countryName => (
                                                <option key={countryName} value={countryName}>{countryName}</option>
                                            ))}
                                        </select>


                                        </div>

                                        <div className="flex">
                                            <button
                                                onClick={goToNewApplicant}
                                                className={`flex items-center p-2 mt-2 bg-green-800 text-white rounded hover:bg-green-400 mr-2`}
                                            >

                                                + New Applicant
                                            </button>
                                            <button
                                                onClick={handleReload}
                                                className={`flex items-center p-2 mt-2 bg-blue-800 hover:bg-blue-400 text-white rounded`}
                                            >
                                                <div className="flex items-center">
                                                <ArrowPathIcon className="h-5 w-5 mr-2" />
                                                Reload
                                            </div>
                                            </button>

                                        </div>





                                    </div>

                                    {/* DataTable */}
                                    <DataTable
                                        value={filteredApplicants}
                                        paginator
                                        rows={8}
                                        dataKey="ref_no"
                                        filters={filters}
                                        globalFilter={globalFilter}
                                        emptyMessage="No applicants found."
                                        className="min-w-full bg-white border border-gray-300 text-neutral-800"
                                    >
                                        <Column className="py-1 pr-0 border-b border-r whitespace-nowrap" field="teegee_ref" header="#Ref No." sortable />
                                        <Column className="py-1 pr-0 border-b border-r whitespace-nowrap" field="fullName" header="Applicant" sortable />
                                        <Column className="py-1 pr-0 border-b border-r whitespace-nowrap" field="postApplied" header="Applied Post" sortable />
                                        <Column className="py-1 pr-0 border-b border-r whitespace-nowrap" field="nationality" header="Country" sortable />
                                        <Column className="py-1 pr-0 border-b border-r whitespace-nowrap" field="monthly_Salary" header="Monthly Salary" sortable />
                                        <Column className="py-1 pr-0 border-b border-r whitespace-nowrap" field="Age" header="Age (yrs)" sortable />
                                        <Column
                                            className="py-1 pr-0 border-b border-r whitespace-nowrap"
                                            header="View CV"
                                            body={(rowData) => (
                                                <Link to={`/applicant/cv/${rowData.ref_no}`}>View</Link>
                                            )}
                                        />
                                        <Column
                                            className="py-1 pr-0 border-b border-r whitespace-nowrap"
                                            header="Actions"
                                            body={(rowData) => (
                                                <>
                                                    <button type="button" onClick={() => handleEditApplicant(rowData.ref_no)}>
                                                        <PencilSquareIcon className="h-5 text-yellow-800 hover:text-yellow-400 mr-2" />
                                                    </button>
                                                    <button type="button" onClick={() => handleDeleteApplicant(rowData.ref_no)}>
                                                        <TrashIcon className="h-5 text-red-800 hover:text-red-400 mr-2" />
                                                    </button>
                                                </>
                                            )}
                                        />
                                    </DataTable>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ManageApplicants;

import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import './css/tailwind.css';

const root = document.getElementById('root') || document.createElement('div');

// Use createRoot to render the app
const reactRoot = ReactDOM.createRoot(root);

// Render the app inside the root
reactRoot.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);

const countries = [
  'Bahrain',
  'Jordan',
  'Kuwait',
  'Lebanon',
  'Malaysia',
  'Oman',
  'Qatar',
  'Romania',
  'Saudi Arabia',
  'Syria',
  'United Arab Emirates',
  'Yemen',
]

const suggestions = countries.map((name, index) => ({ value: index, label: name }))

export { countries, suggestions }

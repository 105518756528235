import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../css/style.css';
import Breadcrumb from '../components/breadcrumb';
import { EyeIcon, HomeIcon, ArrowPathIcon, PencilSquareIcon, TrashIcon } from "@heroicons/react/24/solid";
import Footer from "../components/footer";
import MySQLUrl from "../DBConfig";
import { useNavigate } from "react-router-dom";
import Pagination from "../components/pagination";
import {ArrowDownIcon, ArrowUpIcon} from "@heroicons/react/16/solid";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ManageBlogs = () => {
    const navigate = useNavigate();
    const [User, setUser] = useState([]);
    const [blogData, setBlogData] = useState([]);
    const [currentTime, setCurrentTime] = useState(new Date());
    const [filters, setFilters] = useState({});
    const [subcategories, setSubcategories] = useState([]); // Initialize as an empty array
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize] = useState(5);
    const [sortField, setSortField] = useState(null);
    const [sortOrder, setSortOrder] = useState('asc')
    const [totalBlogs, setTotalBlogs] = useState(0);

    useEffect(() => {
        const fetchData = async () => {
            const storedUser = localStorage.getItem('user');

            if (storedUser) {
                const parsedUser = JSON.parse(storedUser);
                setUser(parsedUser);

                try {
                    const response = await axios.get(`${MySQLUrl}/blog?page=${currentPage}&pageSize=${pageSize}`);
                    const blogsWithSubcategories = await Promise.all(
                        response.data.map(async (blog) => {
                            const subcategoryResponse = await axios.get(`${MySQLUrl}/blog/sub-category/${blog.subcategory_id}`);
                            const subcategoryName = subcategoryResponse.data.subcategory_name;
                            return { ...blog, subcategoryName };
                        })
                    );

                    setBlogData(blogsWithSubcategories);

                } catch (error) {
                    console.error('Error fetching blog data:', error);

                    // Retry after 5 seconds
                    setTimeout(() => {
                        fetchData();
                    }, 5000);
                }
            }
        };

        // Initial data fetch
        fetchData();
    }, [filters, searchTerm, currentPage]);

    useEffect(() => {
        const fetchSubcategories = async () => {
            try {
                const response = await axios.get(`${MySQLUrl}/blog/sub-categories`);
                setSubcategories(response.data);
            } catch (error) {
                console.error('Error fetching subcategories:', error);
            }
        };

        // Fetch subcategories
        fetchSubcategories();
    }, []); // No dependencies, so it runs only once on mount

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentTime(new Date());
        }, 1000); // Update every second

        // Cleanup the interval on component unmount
        return () => clearInterval(intervalId);
    }, []);

    const filteredBlogData = blogData
        .filter(blog => (
            (!filters.subcategory || blog.subcategory_id.toString() === filters.subcategory) &&
            (!searchTerm || blog.title.toLowerCase().includes(searchTerm.toLowerCase()))
        ));

    const fetchTotalBlogs = async () => {
        try {
            const response = await axios.get(`${MySQLUrl}/blog/count`);
            const totalCount = response.data.totalCount; // Assuming the response contains totalCount
            setTotalBlogs(totalCount);
        } catch (error) {
            console.error('Error fetching total count of blogs:', error);
        }
    };

    useEffect(() => {
        fetchTotalBlogs();
    }, []);



    const formattedTime = currentTime.toLocaleTimeString();

    const breadcrumbItems = [
        { label: <HomeIcon className="h-4 w-5" />, url: '/' },
        { label: 'Blogs', url: '/blog' },
    ];

    const handleReload = async () => {
        try {
            const response = await axios.get(`${MySQLUrl}/blog?page=${currentPage}&pageSize=${pageSize}`);
            const blogsWithSubcategories = await Promise.all(
                response.data.map(async (blog) => {
                    const subcategoryResponse = await axios.get(`${MySQLUrl}/blog/sub-category/${blog.subcategory_id}`);
                    const subcategoryName = subcategoryResponse.data.subcategory_name;
                    return { ...blog, subcategoryName };
                })
            );
            setBlogData(blogsWithSubcategories);
            toast.success('Reloaded successfully');

        } catch (error) {
            console.error('Error reloading blog data:', error);
            toast.error('Reloading failed');
        }
    };

    const goToNewBlog = () => {
        navigate('/new-blog');
        console.log('Button clicked!');
    };

    const truncateWords = (text, maxWords) => {
        const words = text.split(' ');
        const truncatedWords = words.slice(0, maxWords);
        return truncatedWords.join(' ') + (words.length > maxWords ? '...' : '');
    };

    const truncateCharacters = (text, maxCharacters) => {
        if (text.length <= maxCharacters) {
            return text;
        }

        const truncatedText = text.slice(0, maxCharacters);
        return truncatedText + '...';
    };


    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const handleSort = (field) => {
        if (sortField === field) {
            setSortOrder((prevOrder) => (prevOrder === 'asc' ? 'desc' : 'asc'));
        } else {
            setSortField(field);
            setSortOrder('asc');
        }
    };

    const renderSortIcon = (field) => {
        if (sortField === field) {
            return sortOrder === 'asc' ? (
                <ArrowUpIcon className="h-4 w-4 text-gray-500 inline-block ml-1" />
            ) : (
                <ArrowDownIcon className="h-4 w-4 text-gray-500 inline-block ml-1" />
            );
        }
        return null;
    };

    const sortedBlogData = [...filteredBlogData].sort((a, b) => {
        if (sortField) {
            const aValue = a[sortField];
            const bValue = b[sortField];

            if (typeof aValue === 'string' && typeof bValue === 'string') {
                return sortOrder === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
            }

            // Add additional checks for other data types if necessary
            return sortOrder === 'asc' ? aValue - bValue : bValue - aValue;
        }

        return 0;
    });

    const handleDeleteBlog = async (blogId) => {
        try {
            // Make a DELETE request to your API endpoint
            await axios.delete(`${MySQLUrl}/blog/delete-post/${blogId}`);

            await handleReload();
            // If the delete request is successful, update the blogData state to reflect the changes
            toast.success('Blog post deleted successfully');
        } catch (error) {
            console.error('Error deleting blog:', error);
            toast.error('Error deleting blog post');
        }
    };



    return (
        <>
            <ToastContainer
                position="top-right" // Adjust position as needed
                autoClose={1000} // Close after 2 seconds
                hideProgressBar={false} // Show progress bar
                newestOnTop={false} // Show newest toast at the top
                closeOnClick // Close toast on click
                rtl={false} // Right to left
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />

        <div>
            <div className="mx-4 text-neutral-800 overflow-y-auto">
                <div className="grid grid-cols-12 col-span-12 gap-4 xxl:col-span-9">
                    <div className="col-span-12 mt-0">
                        <div className="grid gap-2 grid-cols-1 lg:grid-cols-2">
                            <div className="mt-3">
                                <Breadcrumb items={breadcrumbItems} />
                            </div>
                            <div className="mt-3 relative">
                                {User.length === 0 ? (
                                    <div className="absolute right-0">
                                        Welcome, Loading...
                                    </div>
                                ) : (
                                    <div className="absolute right-0 capitalize">
                                        Welcome, <strong>{User.username} </strong> | {formattedTime}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="col-span-12 mt-0">
                        <div className="flex items-center">
                            <h2 className="mr-5 text-lg font-medium truncate ">Blogs</h2>
                        </div>
                    </div>
                    <div className="col-span-12 mt-1">
                        <div className="grid gap-2 grid-cols-1 lg:grid-cols-1">
                            <div className="bg-white p-4 shadow-lg rounded-lg">
                                <div className="grid gap-2 grid-cols-1 lg:grid-cols-2 mb-2">
                                    <h1 className="font-bold text-base">Manage Blogs</h1>
                                    <div className="relative">
                                        <div className="absolute right-0">
                                            {blogData.length === 0 ? (
                                                <button
                                                    onClick={handleReload}
                                                    className={`flex items-center p-2 mt-2 bg-blue-200 text-gray-400 rounded`}
                                                    disabled
                                                >
                                                    <div className="flex items-center">
                                                        <div className="loader me-2"></div>
                                                        Reloading
                                                    </div>
                                                </button>
                                            ) : (
                                                <button
                                                    onClick={handleReload}
                                                    className={`absolute right-0 flex items-center p-2 mt-2 bg-blue-800 hover:bg-blue-400 text-white rounded`}
                                                >
                                                    <div className="flex items-center">
                                                        <ArrowPathIcon className="h-5 w-5 mr-2" />
                                                        Reload
                                                    </div>
                                                </button>
                                            )}
                                        </div>
                                        <div className="absolute right-1/4">
                                            <button
                                                onClick={goToNewBlog}
                                                className={`flex items-center p-2 mt-2 bg-green-800 text-white rounded hover:bg-green-400`}
                                            >
                                                New Blog
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                {/* Filter and Search */}
                                <div className="flex items-center space-x-2">
                                    <label htmlFor="categoryFilter" className="font-medium">Category:</label>
                                    <select
                                        id="categoryFilter"
                                        name="categoryFilter"
                                        value={filters.subcategory || ''}
                                        className="border rounded pl-1 pr-4"
                                        onChange={(e) => setFilters({ ...filters, subcategory: e.target.value })}
                                    >
                                        <option value="">All</option>
                                        {Array.isArray(subcategories) && subcategories.map((subcategory) => (
                                            <option key={subcategory.subcategory_id} value={subcategory.subcategory_id}>
                                                {subcategory.subcategory_name}
                                            </option>
                                        ))}
                                    </select>
                                    <label htmlFor="searchInput" className="font-medium">Search:</label>
                                    <input
                                        type="text"
                                        id="searchInput"
                                        className="appearance-none border rounded"
                                        value={searchTerm}
                                        onChange={(e) => setSearchTerm(e.target.value)}
                                    />
                                </div>
                                <div className="mt-4">
                                    <div className="flex flex-col">
                                        <div className="-my-2 overflow-x-auto overflow-y-auto">
                                            <div className="py-2 align-middle inline-block min-w-full">
                                                <div
                                                    className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg bg-white">
                                                    <div className="relative  overflow-y-auto ">
                                                        {filteredBlogData.length === 0 ? (
                                                            <div className="flex flex-col items-center justify-center">
                                                                <div className="loader"></div>
                                                                <p>Loading...</p>
                                                            </div>
                                                        ) : (
                                                            <div className="overflow-y-auto max-h-[200px]">
                                                            <table className="min-w-full bg-white border border-gray-300 text-neutral-800">
                                                                <thead className="relative">
                                                                <tr>
                                                                    <th className="py-2 px-4 border-b whitespace-nowrap"
                                                                        onClick={() => handleSort('blog_id')}>
                                                                        No. {renderSortIcon('blog_id')}
                                                                    </th>
                                                                    <th className="py-2 px-4 border-b whitespace-nowrap"
                                                                        onClick={() => handleSort('title')}>
                                                                        Title {renderSortIcon('title')}
                                                                    </th>
                                                                    <th className="py-2 px-4 border-b whitespace-nowrap">Sub Category</th>
                                                                    <th className="py-2 px-4 border-b whitespace-nowrap">Content</th>
                                                                    <th className="py-2 px-4 border-b whitespace-nowrap">Views</th>
                                                                    <th className="py-2 px-4 border-b whitespace-nowrap">Actions</th>
                                                                </tr>
                                                                </thead>
                                                                <tbody>
                                                                {sortedBlogData.map(blog => (
                                                                    <tr key={blog.blog_id}>
                                                                        <td className="py-2 px-4 border-b whitespace-nowrap">{blog.blog_id}</td>
                                                                        <td className="py-2 px-4 border-b whitespace-nowrap">{blog.title}</td>
                                                                        <td className="py-2 px-4 border-b whitespace-nowrap">{blog.subcategoryName}</td>
                                                                        <td className="py-2 px-4 border-b whitespace-nowrap">{truncateCharacters(blog.content, 50)}</td>
                                                                        <td className="py-2 px-4 border-b flex items-center whitespace-nowrap">
                                                                            <EyeIcon className="h-4" />{blog.view_count}
                                                                        </td>
                                                                        <td className="py-2 px-4 border-b whitespace-nowrap">
                                                                            <div>
                                                                                {/*<button type="button" className="text-yellow-800 hover:text-yellow-400 mr-2">*/}
                                                                                {/*    <PencilSquareIcon className="h-5 text-current"/>*/}
                                                                                {/*</button>*/}
                                                                                <button type="button"
                                                                                        className="text-red-800 hover:text-red-400 mr-2"
                                                                                        onClick={() => handleDeleteBlog(blog.blog_id)}>
                                                                                    <TrashIcon className="h-5 text-current"/>
                                                                                </button>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                                </tbody>
                                                            </table>
                                                            </div>
                                                        )}
                                                        {/* Results Count */}
                                                        <div className="text-sm text-gray-600 mt-2">
                                                            {totalBlogs === 0 ? 'No results found.' : `Showing ${((currentPage - 1) * pageSize) + 1}-${Math.min(currentPage * pageSize, totalBlogs)} of ${totalBlogs} results.`}
                                                        </div>
                                                        <div className="mb-4">
                                                            {/* Pagination */}
                                                            <Pagination
                                                                currentPage={currentPage}
                                                                pageSize={pageSize}
                                                                totalItems={totalBlogs}
                                                                onPageChange={handlePageChange}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        </>
    );
};

export default ManageBlogs;

import React, { useEffect, useLayoutEffect, useState } from "react";
import { Beforeunload } from "react-beforeunload";
import Header from "../components/header";
import Sidebar from "../components/sidepane";
import { Route, Routes, useNavigate } from "react-router-dom";
import Home from "../pages/Home";
import Settings from "../pages/Settings";
import ManageBlogs from "../pages/Blogs";
import ManageVacancies from "../pages/Vacancies";
import ManageApplicants from "../pages/Applicants";
import NewBlogs from "../pages/NewBlog";
import NewVacancy from "../pages/NewVacancy";
import NewApplicants from "../pages/NewApplicants";
import ManagePartnerGallery from "../pages/PartnerGallery";
import SendSms from "../pages/SendSMS";
import Pdf from "../components/pdf";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SMS from "../pages/SMS";
import Footer from "../components/footer";
import axios from "axios";
import MySQLUrl from "../DBConfig";
import NewPartnerGallery from "../pages/NewPartnerGallery";
import EditApplicant from "../pages/EditApplicants";
import Workplace from "../pages/Workplace";
import NewWorkplace from "../pages/NewWorkplace";
import TimeBar from "../components/TimeBar";
import ApplicantOtherDetails from "../pages/ApplicantOtherDetails";

const Dashboard = () => {
  const [logoutTimer, setLogoutTimer] = useState("");
  const navigate = useNavigate();

  // console.log(expiresIn);

  // useEffect(() => {
    // const expiresIn = localStorage.getItem("expiresIn");

    // const handleLogout = () => {
    //   localStorage.removeItem("accessToken");
    //   localStorage.removeItem("smsToken");
    //   localStorage.removeItem("smsUserData");
    //   localStorage.removeItem("user");
    //   localStorage.removeItem("expiresIn");
    //   navigate("/login");
    // };

    // Convert expiresIn to milliseconds
    // const expiresInMs = expiresIn ? parseInt(expiresIn) * 2000 : 0;

    // If expiresInMs is greater than 0, set a timer to trigger logout
    // if (expiresInMs > 0) {
    //   const timeoutId = setTimeout(handleLogout, expiresInMs);
    //
    //   // Set the logout timer
    //   setLogoutTimer(timeoutId);
    // }

    // return () => {
    //   // Clear the logout timer if it exists
    //   if (logoutTimer) {
    //     clearTimeout(logoutTimer);
    //   }
    // };
  // }, [navigate]);

  const handleLogout = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("smsToken");
    localStorage.removeItem("smsUserData");
    localStorage.removeItem("user");
    localStorage.removeItem("expiresIn");
    // Navigate to the login page when the application is closed
    navigate("/login");
  };

  // useEffect(() => {

  //     const handleBeforeUnload = () => {
  //         localStorage.removeItem('isLoggedIn'); // Clear login status on tab close
  //         setIsLoggedIn(false);

  //     };

  //     if (!isLoggedIn) {
  //         handleLogout()
  //     } else {

  //     }

  //     window.addEventListener('onbeforeunload', handleBeforeUnload);

  //     return () => {
  //         window.removeEventListener('onunload', handleBeforeUnload);
  //     };
  // }, [navigate]);

  useEffect(() => {
    const handleContextMenu = (event) => {
      event.preventDefault(); // Prevent default right-click behavior
    };

    window.addEventListener("contextmenu", handleContextMenu);

    return () => {
      window.removeEventListener("contextmenu", handleContextMenu);
    };
  }, []);

  useEffect(() => {
    const fetchSMSToken = async () => {
      try {
        const response = await axios.get(`${MySQLUrl}/sms/get-token`);
        const smsToken = response.data.smsToken;

        // console.log(smsToken)
        // Store the new token and its timestamp in local storage
        localStorage.setItem("smsToken", smsToken.token);
        localStorage.setItem("smsUserData", JSON.stringify(smsToken.userData));
      } catch (error) {
        console.error("Error fetching SMS token:", error);
        // Handle error fetching token
      }
    };

    fetchSMSToken();
  });

  return (
    <>
      <ToastContainer
        position="top-right" // Adjust position as needed
        hideProgressBar={true} // hide progress bar
        newestOnTop={false} // Show newest toast at the top
        closeOnClick // Close toast on click
        rtl={false} // Right to left
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <Beforeunload onBeforeunload={() => {
        handleLogout(); }} />

      <div className="h-screen max-h-screen flex flex-col bg-neutral-200">
        <Header />
        <TimeBar />

        <div className="flex-grow overflow-y-auto flex">
          <Sidebar />

          <div className="container p-0 text-light">
            <Routes>
              <Route exact path="/" element={<Home />} />
              <Route exact path="/settings" element={<Settings />} />
              <Route exact path="/blog" element={<ManageBlogs />} />
              <Route exact path="/vacancy" element={<ManageVacancies />} />
              <Route exact path="/applicant" element={<ManageApplicants />} />
              <Route exact path="/applicants" element={<ManageApplicants />} />
              <Route
                exact
                path="/partner-gallery"
                element={<ManagePartnerGallery />}
              />
              <Route
                exact
                path="/partner-gallery/new"
                element={<NewPartnerGallery />}
              />
              <Route exact path="/new-blog" element={<NewBlogs />} />
              <Route exact path="/new-vacancy" element={<NewVacancy />} />
              <Route exact path="/new-applicants" element={<NewApplicants />} />
              <Route
                exact
                path="/applicant/edit/:applicantId"
                element={<EditApplicant />}
              />
              <Route
                exact
                path="/applicant/details/:applicantId"
                element={<ApplicantOtherDetails />}
              />
              <Route exact path="/sms/new" element={<SendSms />} />
              <Route exact path="/sms/history" element={<SMS />} />

              <Route exact path="/workplace" element={<Workplace />} />
              <Route exact path="/workplace/new" element={<NewWorkplace />} />

              <Route
                exact
                path="/applicant/cv/:applicantId"
                element={<Pdf />}
              />
            </Routes>
          </div>
        </div>

        <Footer classname="fixed bottom-0" />
      </div>
    </>
  );
};

export default Dashboard;

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { HomeIcon, ArrowPathIcon, PencilSquareIcon, TrashIcon } from "@heroicons/react/24/solid";
import { ArrowDownIcon, ArrowUpIcon } from "@heroicons/react/16/solid";
import {Link, useNavigate} from "react-router-dom";
import Pagination from "../components/pagination";
import Footer from "../components/footer";
import MySQLUrl from "../DBConfig";
import Breadcrumb from "../components/breadcrumb";
import {toast, ToastContainer} from "react-toastify";
import { confirmAlert } from 'react-confirm-alert'; // Import react-confirm-alert module
import 'react-confirm-alert/src/react-confirm-alert.css';

const SMS = () => {
    const navigate = useNavigate();
    const [User, setUser] = useState([]);
    const [SMSData, setSMSData] = useState([]);
    const [currentTime, setCurrentTime] = useState(new Date());
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize] = useState(10);
    const [sortField, setSortField] = useState(null);
    const [sortOrder, setSortOrder] = useState('asc');
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            const storedUser = localStorage.getItem('user');

            if (storedUser) {
                const parsedUser = JSON.parse(storedUser);
                setUser(parsedUser);

                try {
                    const response = await axios.get(`${MySQLUrl}/sms/history`);
                    const allSMS = response.data;

                    setSMSData(allSMS);
                } catch (error) {
                    console.error('Error fetching data:', error);

                    // Retry after 5 seconds
                    setTimeout(() => {
                        fetchData();
                    }, 5000);
                }
            }
        };

        // Initial data fetch
        fetchData();
    }, []);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentTime(new Date());
        }, 1000); // Update every second

        // Cleanup the interval on component unmount
        return () => clearInterval(intervalId);
    }, []);


    const formattedTime = currentTime.toLocaleTimeString();

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const handleSort = (field) => {
        if (sortField === field) {
            setSortOrder((prevOrder) => (prevOrder === 'asc' ? 'desc' : 'asc'));
        } else {
            setSortField(field);
            setSortOrder('asc');
        }
    };

    const filteredSMSData = SMSData.filter(sms => (
        !searchTerm || sms.applicant.applicant_name.includes(searchTerm)
    ));


    const renderSortIcon = (field) => {
        if (sortField === field) {
            return sortOrder === 'asc' ? (
                <ArrowUpIcon className="h-4 w-4 text-gray-500 inline-block ml-1" />
            ) : (
                <ArrowDownIcon className="h-4 w-4 text-gray-500 inline-block ml-1" />
            );
        }
        return null;
    };

    const sortedSMSData = [...filteredSMSData].sort((a, b) => {
        if (sortField) {
            const aValue = a[sortField];
            const bValue = b[sortField];

            if (typeof aValue === 'string' && typeof bValue === 'string') {
                return sortOrder === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
            }

            // Add additional checks for other data types if necessary
            return sortOrder === 'asc' ? aValue - bValue : bValue - aValue;
        }

        return 0;
    });

    const handleReload = async () => {
        try {
            const response = await axios.get(`${MySQLUrl}/sms/history`);
            const allSMS = response.data;
            setSMSData(allSMS);
            toast.success('Reloaded successfully');
        } catch (error) {
            console.error('Error reloading sms data:', error);
            toast.error('Reloading failed');
        }
    };



    const breadcrumbItems = [
        { label: <HomeIcon className="h-4 w-5" />, url: '/' },
        { label: 'SMS', url: '/sms/history' },
    ];


    const truncateWords = (text, maxWords) => {
        const words = text.split(' ');
        const truncatedWords = words.slice(0, maxWords);
        return truncatedWords.join(' ') + (words.length > maxWords ? '...' : '');
    };

    const goToNewSMS = () => {
        navigate('/sms/new');
        console.log('Button clicked!');
    };


    return (
        <>
            <ToastContainer
                position="top-right" // Adjust position as needed
                autoClose={1000} // Close after 2 seconds
                hideProgressBar={false} // Show progress bar
                newestOnTop={false} // Show newest toast at the top
                closeOnClick // Close toast on click
                rtl={false} // Right to left
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />

        <div>
            <div className="mx-4 text-neutral-800 overflow-y-auto">
                <div className="grid grid-cols-12 col-span-12 gap-4 xxl:col-span-9">
                    <div className="col-span-12 mt-0">
                        <div className="grid gap-2 grid-cols-1 lg:grid-cols-2">
                            <div className="mt-3">
                                <Breadcrumb items={breadcrumbItems} />
                            </div>
                            <div className="mt-3 relative">
                                {User.length === 0 ? (
                                    <div className="absolute right-0">
                                        Welcome, Loading...
                                    </div>
                                ) : (
                                    <div className="absolute right-0 capitalize">
                                        Welcome, <strong>{User.username} </strong> | {formattedTime}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="col-span-12 mt-0">
                        <div className="flex items-center">
                            <h2 className="mr-5 text-lg font-medium truncate ">SMS History</h2>
                        </div>
                    </div>
                    <div className="col-span-12 mt-1">

                        <div className="grid gap-2 grid-cols-1 lg:grid-cols-1 mb-2">
                            <div className="bg-white p-4 shadow-lg rounded-lg">
                                <div className="grid gap-2 grid-cols-1 lg:grid-cols-2 mb-2">
                                    <h1 className="font-bold text-base">View History</h1>
                                    <div className="relative">
                                        <div className="absolute right-0">
                                            {SMSData.length === 0 ? (
                                                <button
                                                    onClick={handleReload}
                                                    className={`flex items-center p-2 mt-2 bg-blue-200 text-gray-400 rounded`}
                                                    disabled
                                                >
                                                    <div className="flex items-center">
                                                        <div className="loader me-2"></div>
                                                        Reloading
                                                    </div>
                                                </button>
                                            ) : (
                                                <button
                                                    onClick={handleReload}
                                                    className={`absolute right-0 flex items-center p-2 mt-2 bg-blue-800 hover:bg-blue-400 text-white rounded`}
                                                >
                                                    <div className="flex items-center">
                                                        <ArrowPathIcon className="h-5 w-5 mr-2" />
                                                        Reload
                                                    </div>
                                                </button>
                                            )}
                                        </div>
                                        <div className="absolute right-1/4">
                                            <button
                                                onClick={goToNewSMS}
                                                className={`flex items-center p-2 mt-2 bg-green-800 text-white rounded hover:bg-green-400`}
                                            >
                                                Send New SMS
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <div className="mt-4">
                                    <div className="flex flex-col">
                                        <div className="-my-2 overflow-x-auto overflow-y-auto">
                                            <div className="py-2 align-middle inline-block min-w-full">
                                                <div
                                                    className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg bg-white">
                                                    <div className="relative max-h-[50vh] overflow-y-auto">
                                                        {filteredSMSData.length === 0 ? (
                                                            <div className="flex flex-col items-center justify-center">
                                                                <div className="loader"></div>
                                                                <p>Loading...</p>
                                                            </div>
                                                        ) : (

                                                            <table className="min-w-full bg-white border border-gray-300 text-neutral-800">
                                                                <thead>
                                                                <tr>
                                                                    <th className="py-2 px-4 border-b whitespace-nowrap"
                                                                        onClick={() => handleSort('sms_id')}>
                                                                        #Ref No. {renderSortIcon('sms_id')}
                                                                    </th>
                                                                    <th className="py-2 px-4 border-b whitespace-nowrap">
                                                                        Mobile No.
                                                                    </th>
                                                                    <th className="py-2 px-4 border-b whitespace-nowrap">
                                                                        Message
                                                                    </th>
                                                                    <th className="py-2 px-4 border-b whitespace-nowrap"
                                                                        onClick={() => handleSort('date_sent')}>
                                                                        Date Sent {renderSortIcon('date_sent')}
                                                                    </th>

                                                                </tr>
                                                                </thead>
                                                                <tbody>
                                                                {sortedSMSData.map(sms => (
                                                                    <tr key={sms.sms_id}>
                                                                        <td className="py-2 px-4 border-b whitespace-nowrap">{sms.sms_id}</td>
                                                                        <td className="py-2 px-4 border-b whitespace-nowrap">{sms.mobile_nos}</td>
                                                                        <td className="py-2 px-4 border-b whitespace-nowrap">{sms.message}</td>
                                                                        <td className="py-2 px-4 border-b whitespace-nowrap">{new Date(sms.date_sent).toLocaleString()}</td>

                                                                    </tr>
                                                                ))}
                                                                </tbody>

                                                            </table>
                                                        )}
                                                        {/* Results Count */}
                                                        <div className="text-sm text-gray-600 mt-2">
                                                            {sortedSMSData.length === 0 ? 'No results found.' : `Showing ${((currentPage - 1) * pageSize) + 1}-${Math.min(currentPage * pageSize, sortedSMSData.length)} of ${sortedSMSData.length} results.`}
                                                        </div>
                                                        <div className="mb-4">
                                                            {/* Pagination */}
                                                            <Pagination
                                                                currentPage={currentPage}
                                                                pageSize={pageSize}
                                                                totalItems={setSMSData.length}
                                                                onPageChange={handlePageChange}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        </>
    );
};

export default SMS;

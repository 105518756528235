import React, {useEffect, useState} from 'react';
import {Link, useNavigate} from "react-router-dom";
import axios from "axios";
import MySQLUrl from "../DBConfig";
import {ArrowDownIcon, ArrowUpIcon} from "@heroicons/react/16/solid";
import {toast, ToastContainer} from "react-toastify";
import {ArrowPathIcon, HomeIcon, PencilSquareIcon, TrashIcon} from "@heroicons/react/24/solid";
import {confirmAlert} from "react-confirm-alert";
import Breadcrumb from "../components/breadcrumb";
import Pagination from "../components/pagination";

const Workplace = () => {
    const navigate = useNavigate();
    const [User, setUser] = useState([]);
    const [WorkplaceData, setWorkplaceData] = useState([]);
    const [currentTime, setCurrentTime] = useState(new Date());
    const [accessToken, setAccessToken]=useState('');


    useEffect(() => {
        const fetchData = async () => {
            const storedUser = localStorage.getItem('user');

            if (storedUser) {
                const parsedUser = JSON.parse(storedUser);
                setUser(parsedUser);

                const accessToken = localStorage.getItem('accessToken');



                if(!accessToken){
                    localStorage.removeItem('smsToken');
                    localStorage.removeItem('smsUserData');
                    localStorage.removeItem('user');
                    navigate('/login');
                }

                setAccessToken(accessToken);


                try {
                    const response = await axios.get(`${MySQLUrl}/workplace/all`);
                    const allWorkplaceData = response.data;

                    setWorkplaceData(allWorkplaceData);
                } catch (error) {
                    console.error('Error fetching data:', error);

                    // Retry after 5 seconds
                    setTimeout(() => {
                        fetchData();
                    }, 5000);
                }
            }
        };

        // Initial data fetch
        fetchData();
    }, []);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentTime(new Date());
        }, 1000); // Update every second

        // Cleanup the interval on component unmount
        return () => clearInterval(intervalId);
    }, []);


    const formattedTime = currentTime.toLocaleTimeString();



    const handleDelete = async (workplaceId) => {
        confirmAlert({
            title: 'Confirm Deletion',
            message: 'Are you sure you want to delete this Image?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {
                        try {

                            const config = {
                                headers: {
                                    Authorization: `Bearer ${accessToken}`
                                }
                            };

                            await axios.delete(`${MySQLUrl}/workplace/delete/${workplaceId}`, config);
                            await handleReload();
                            toast.success('Image deleted successfully');
                        } catch (error) {
                            console.error('Error deleting image:', error);
                            toast.error('Error deleting image');
                        }
                    }
                },
                {
                    label: 'No',
                    onClick: () => {} // Do nothing if "No" is clicked
                }
            ]
        });
    };





    const handleReload = async () => {
        try {
            const response = await axios.get(`${MySQLUrl}/workplace/all`);
            const allWorkplaceData = response.data;
            setWorkplaceData(allWorkplaceData);
            toast.success("Reload Successful");
        } catch (error) {
            console.error('Error reloading workplace data:', error);
        }
    };

    const goToNewCollection = () => {
        navigate('/workplace/new');
        console.log('Button clicked!');
    };



    const breadcrumbItems = [
        { label: <HomeIcon className="h-4 w-5" />, url: '/' },
        { label: 'Projects', url: '/partner-gallery' },
    ];

    const formatDate = (timestamp) => {
        const date = new Date(timestamp);
        return date.toLocaleString(); // You can customize the format using options
    };


    return (
        <>
            <ToastContainer
                position="top-right" // Adjust position as needed
                autoClose={1000} // Close after 2 seconds
                hideProgressBar={false} // Show progress bar
                newestOnTop={false} // Show newest toast at the top
                closeOnClick // Close toast on click
                rtl={false} // Right to left
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />

            <div>
                <div className="mx-4 text-neutral-800 overflow-y-auto">
                    <div className="grid grid-cols-12 col-span-12 gap-4 xxl:col-span-9">
                        <div className="col-span-12 mt-0">
                            <div className="grid gap-2 grid-cols-1 lg:grid-cols-2">
                                <div className="mt-3">
                                    <Breadcrumb items={breadcrumbItems} />
                                </div>
                                <div className="mt-3 relative">
                                    {User.length === 0 ? (
                                        <div className="absolute right-0">
                                            Welcome, Loading...
                                        </div>
                                    ) : (
                                        <div className="absolute right-0 capitalize">
                                            Welcome, <strong>{User.username} </strong> | {formattedTime}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="col-span-12 mt-0">
                            <div className="flex items-center">
                                <h2 className="mr-5 text-lg font-medium truncate ">Workplace Gallery</h2>
                            </div>
                        </div>
                        <div className="col-span-12 mt-1">

                            <div className="grid gap-2 grid-cols-1 lg:grid-cols-1 mb-2">
                                <div className="bg-white p-4 shadow-lg rounded-lg">
                                    <div className="grid gap-2 grid-cols-1 lg:grid-cols-2 mb-2">
                                        <h1 className="font-bold text-base">Manage Workplace</h1>
                                        <div className="relative">
                                            <div className="absolute right-0">
                                                {WorkplaceData.length === 0 ? (
                                                    <button
                                                        onClick={handleReload}
                                                        className={`flex items-center p-2 mt-2 bg-blue-200 text-gray-400 rounded`}
                                                        disabled
                                                    >
                                                        <div className="flex items-center">
                                                            <div className="loader me-2"></div>
                                                            Reloading
                                                        </div>
                                                    </button>
                                                ) : (
                                                    <button
                                                        onClick={handleReload}
                                                        className={`absolute right-0 flex items-center p-2 mt-2 bg-blue-800 hover:bg-blue-400 text-white rounded`}
                                                    >
                                                        <div className="flex items-center">
                                                            <ArrowPathIcon className="h-5 w-5 mr-2" />
                                                            Reload
                                                        </div>
                                                    </button>
                                                )}
                                            </div>
                                            <div className="absolute right-1/4">
                                                <button
                                                    onClick={goToNewCollection}
                                                    className={`flex items-center p-2 mt-2 bg-green-800 text-white rounded hover:bg-green-400`}
                                                >
                                                    New Image
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mt-4">
                                        <div className="flex flex-col">
                                            <div className="-my-2 overflow-x-auto overflow-y-auto">
                                                <div className="py-2 align-middle inline-block min-w-full">
                                                    <div
                                                        className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg bg-white">
                                                        <div className="relative max-h-[55vh] overflow-y-auto">
                                                            {WorkplaceData.length === 0 ? (
                                                                <div className="flex flex-col items-center justify-center">
                                                                    <div className="loader"></div>
                                                                    <p>Loading...</p>
                                                                </div>
                                                            ) : (
                                                                <div className="grid grid-cols-4 gap-4 mt-3">
                                                                    {WorkplaceData.map((workplace) => {
                                                                        const timestamp = workplace.createdAt;
                                                                        const formattedDateTime = new Date(timestamp).toLocaleString();

                                                                        return (
                                                                            <div key={workplace.id} className="border p-4 relative">
                                                                                {/* Render your image or workplace data here */}
                                                                                <img src={`${MySQLUrl}/${workplace.webpFilename}`} alt={workplace.name} />
                                                                                <p className="font-bold">Added Date: {formattedDateTime}</p>
                                                                                {/* Buttons */}
                                                                                <div className="">
                                                                                    <button
                                                                                        onClick={() => handleDelete(workplace.id)}
                                                                                        className="bg-red-500 py-2 px-4 rounded mr-2 ">
                                                                                        <TrashIcon
                                                                                            className="h-5 w-5 text-neutral-200"/>
                                                                                    </button>
                                                                                </div>
                                                                            </div>

                                                                        );
                                                                    })}
                                                                </div>


                                                            )}

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </>
    );
};

export default Workplace;
import React from 'react';
import poweredLogo from '../images/logo/techzap-logo.webp';

const Footer = () => {

    const poweredByText = "Powered By ";

    return (
        <footer className="bg-gray-800 text-white p-2 items-center">
            <div className="flex  items-center justify-center">
            <p>{poweredByText}</p>
            <img src={poweredLogo} alt="Powered by" className="h-8" />
            </div>
        </footer>
    );
};

export default Footer;

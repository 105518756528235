import React, {useEffect, useState} from 'react';
import axios from "axios";
import MySQLUrl from "../DBConfig";
import {useNavigate, useParams} from "react-router-dom";
import Breadcrumb from "../components/breadcrumb";
import {toast, ToastContainer} from "react-toastify";
import {HomeIcon, TrashIcon} from "@heroicons/react/24/solid";
import {confirmAlert} from "react-confirm-alert";
import { countries } from '../components/countries'

const ApplicantOtherDetails = () => {
    const { applicantId } = useParams();
    const navigate = useNavigate();
    const [User, setUser] = useState([]);
    const [qualificationLoading, setQualificationLoading] = useState(true);
    const [experienceLoading, setExperienceLoading] = useState(true);
    const [countriesLoading, setCountriesLoading] = useState(true);
    const [countriesList, setCountriesList]=useState([{}]);
    const [qualification, setQualification]=useState([]);
    const [experience, setExperience]=useState([]);
    const [accessToken, setAccessToken]=useState('');
    const [loading, setLoading] = useState(true);
    const [currentTime, setCurrentTime] = useState(new Date());
    const [isOpenQualification, setIsOpenQualification] = useState(false);
    const [isOpenExperience, setIsOpenExperience] = useState(false);
    const [isOpenCountry, setIsOpenCountry] = useState(false);

    const [formData, setFormData] = useState({
        qualification:'',
        country:'',
        duration:'',
        visibleCountry:'',
    });

    const openModalQualification = () => {
        setIsOpenQualification(true);
    };

    const closeModalQualification = () => {
        setIsOpenQualification(false);
    };

    const openModalExperience = () => {
        setIsOpenExperience(true);
    };

    const closeModalExperience= () => {
        setIsOpenExperience(false);
    };

    const openModalCountry = () => {
        setIsOpenCountry(true);
    };

    const closeModalCountry= () => {
        setIsOpenCountry(false);
    };





    useEffect(() => {
        const fetchData = async () => {
            const storedUser = localStorage.getItem('user');

            if (storedUser) {
                const parsedUser = JSON.parse(storedUser);
                setUser(parsedUser);
            }

            const accessToken = localStorage.getItem('accessToken');

            if (!accessToken) {
                localStorage.removeItem('smsToken');
                localStorage.removeItem('smsUserData');
                localStorage.removeItem('user');
                navigate('/login');
            }

            setAccessToken(accessToken);


            await fetchQualification();
            await fetchExperience();
            await fetchCountries();

            }


        fetchData();
    }, []);


    const config = {
        headers: {
            Authorization: `Bearer ${accessToken}`
        }
    };

    const handleReload = async () => {
        await fetchQualification();
        await fetchExperience();
        await fetchCountries();
        // toast.success("Reload Successful")
    };


    const fetchQualification = async () => {
        try {
            const response = await axios.get(`${MySQLUrl}/applicant/qualification/${applicantId}`, config);
            const qualificationData = response.data;

            setQualification(qualificationData);
            setQualificationLoading(false);

        } catch (error) {
            console.error("Error fetching applicant data:", error);

        }
    };

    const fetchExperience = async () => {
        try {
            const response = await axios.get(`${MySQLUrl}/applicant/prev-employment/${applicantId}`, config);
            const experienceData = response.data;

            setExperience(experienceData);
            setExperienceLoading(false);

        } catch (error) {
            console.error("Error fetching applicant data:", error);

        }
    };


    const fetchCountries = async () => {
        try {
            const response = await axios.get(`${MySQLUrl}/applicant/available-countries/${applicantId}`, config);
            const countryData = response.data;

            setCountriesList(countryData);
            setCountriesLoading(false);

        } catch (error) {
            console.error("Error fetching applicant data:", error);

        }
    };


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };


    const handleQualificationFormSubmit = async (e) => {
        e.preventDefault();

        try {

            const config = {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            };

            const qualificationData = {
                applicantId: applicantId,
                qualification: formData.qualification
            };


            // Send form data
            const response = await axios.post(`${MySQLUrl}/applicant/qualification/new`, qualificationData, config);
            console.log('Response from the server:', response.data);

            // Clear form data and preview images
            setFormData({
                qualification: '',
            });

            toast.success("Successfully added qualification")
            closeModalQualification();
            await handleReload();

            // Optionally, you can redirect the user to a new page or show a success message
        } catch (error) {
            toast.error('Error adding applicant');
            console.error('Error submitting form:', error);
            // Handle error, show an error message, etc.
        }
    };

    const handleExperienceFormSubmit = async (e) => {
        e.preventDefault();

        try {

            const config = {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            };

            const experienceData = {
                applicantId: applicantId,
                country: formData.country,
                duration: formData.duration,
            };


            // Send form data
            const response = await axios.post(`${MySQLUrl}/applicant/prev-employment/new`, experienceData, config);
            console.log('Response from the server:', response.data);

            // Clear form data and preview images
            setFormData({
                country: '',
                duration: '',
            });

            toast.success("Successfully added experience")
            closeModalExperience();
            await handleReload();

            // Optionally, you can redirect the user to a new page or show a success message
        } catch (error) {
            toast.error('Error adding applicant');
            console.error('Error submitting form:', error);
            // Handle error, show an error message, etc.
        }
    };

    const handleCountryFormSubmit = async (e) => {
        e.preventDefault();

        try {

            const config = {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            };

            const countryData = {
                applicantId: applicantId,
                country: formData.visibleCountry,
            };


            // Send form data
            const response = await axios.post(`${MySQLUrl}/applicant/visible-countries/new`, countryData, config);
            console.log('Response from the server:', response.data);

            // Clear form data and preview images
            setFormData({
                visibleCountry: '',
            });

            toast.success("Successfully added country")
            closeModalCountry();
            await handleReload();

            // Optionally, you can redirect the user to a new page or show a success message
        } catch (error) {
            toast.error('Error adding applicant');
            console.error('Error submitting form:', error);
            // Handle error, show an error message, etc.
        }
    };

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentTime(new Date());
        }, 1000); // Update every second

        // Cleanup the interval on component unmount
        return () => clearInterval(intervalId);
    }, []);

    const formattedTime = currentTime.toLocaleTimeString();

    const breadcrumbItems = [
        { label: <HomeIcon className="h-4 w-5" />, url: "/" },
        { label: "Applicants", url: "/applicants" },
    ];


    const handleDeleteExperience = async (experienceId) => {
        confirmAlert({
            title: 'Confirm Deletion',
            message: 'Are you sure you want to delete this experience of this applicant?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {
                        try {

                            const config = {
                                headers: {
                                    Authorization: `Bearer ${accessToken}`
                                }
                            };

                            await axios.delete(`${MySQLUrl}/applicant/prev-employment/delete/${experienceId}`, config);
                            // await handleReload();
                            toast.success('Applicant Experience deleted successfully');
                            await handleReload();
                        } catch (error) {
                            console.error('Error deleting applicant:', error);
                            toast.error('Error deleting applicant');
                        }
                    }
                },
                {
                    label: 'No',
                    onClick: () => {} // Do nothing if "No" is clicked
                }
            ]
        });
    };


    const handleDeleteQualification = async (qualificationId) => {
        confirmAlert({
            title: 'Confirm Deletion',
            message: 'Are you sure you want to delete this qualification of this applicant?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {
                        try {

                            const config = {
                                headers: {
                                    Authorization: `Bearer ${accessToken}`
                                }
                            };

                            await axios.delete(`${MySQLUrl}/applicant/qualification/delete/${qualificationId}`, config);
                            // await handleReload();
                            toast.success('Applicant qualification deleted successfully');
                            await handleReload();
                        } catch (error) {
                            console.error('Error deleting applicant:', error);
                            toast.error('Error deleting applicant');
                        }
                    }
                },
                {
                    label: 'No',
                    onClick: () => {} // Do nothing if "No" is clicked
                }
            ]
        });
    };

    const handleDeleteCountries = async (countryId) => {
        confirmAlert({
            title: 'Confirm Deletion',
            message: 'Are you sure you want to delete this qualification of this applicant?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {
                        try {

                            const config = {
                                headers: {
                                    Authorization: `Bearer ${accessToken}`
                                }
                            };

                            await axios.delete(`${MySQLUrl}/applicant/available-countries/delete/${countryId}`, config);
                            // await handleReload();
                            toast.success('Applicant qualification deleted successfully');
                            await handleReload();
                        } catch (error) {
                            console.error('Error deleting applicant:', error);
                            toast.error('Error deleting applicant');
                        }
                    }
                },
                {
                    label: 'No',
                    onClick: () => {} // Do nothing if "No" is clicked
                }
            ]
        });
    };


    return (
        <>
            <ToastContainer
                position="bottom-left"
                autoClose={1000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />

            <div className="flex flex-col justify-start items-center">
                <div className="w-9/12 overscroll-y-auto">
                        <div className="col-span-12 mt-0 text-gray-800">
                            <div className="grid gap-2 grid-cols-1 lg:grid-cols-2">
                                <div className="mt-3">
                                    <Breadcrumb items={breadcrumbItems} />
                                </div>
                                <div className="mt-3 relative">
                                    {User.length === 0 ? (
                                        <div className="absolute right-0">
                                            Welcome, Loading...
                                        </div>
                                    ) : (
                                        <div className="absolute right-0 capitalize">
                                            Welcome, <strong>{User.username} </strong> | {formattedTime}
                                        </div>
                                    )}
                                </div>
                            </div>
                    </div>

                    <section className="px-8 w-full h-auto sm:top-[450px] max-w-7xl mt-3 mb-4 flex justify-center items-center">
                        <div className="flex justify-center text-center items-center">
                            <h1 className="font-bold text-gray-800 text-xl">Applicant Overview</h1>
                        </div>
                    </section>



                    <div className="flex flex-col md:flex-row md:justify-between gap-1 mt-2 ">



                        <div className="w-full md:w-12/12 h-[60vh] overflow-y-auto">


                            {/* 2 new div */}
                            <div className="h-auto m-1 flex flex-col">
                                <div className="bg-white p-2 shadow-md mt-2">
                                    <h2 className="text-lg font-semibold text-gray-800">Education Qualification</h2>

                                    {qualificationLoading ? (
                                        <div className="flex flex-col items-center justify-center">
                                            <div className="loader"></div>
                                            <p>Loading...</p>
                                        </div>

                                    ):(
                                        <div >
                                            <div className={"flex justify-end items-end mb-1"}>
                                            <button onClick={openModalQualification} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                                                Add Qualification
                                            </button>
                                            </div>
                                            <table className={"min-w-full bg-white border border-gray-300 text-neutral-800"}>
                                                <thead>
                                                <tr>
                                                    <th className={"py-2 px-4 border-b whitespace-nowrap"}>
                                                        Education
                                                    </th>
                                                    <th className={"py-2 px-4 border-b whitespace-nowrap"}>
                                                        Action
                                                    </th>
                                                </tr>
                                                </thead>

                                            {qualification.map(qualification => (
                                                <tr key={qualification.id}>
                                                    <td className="py-2 px-4 border-b whitespace-nowrap">
                                                        {qualification.qualification}
                                                    </td>
                                                    <td className="py-2 px-4 border-b whitespace-nowrap">
                                                        <button
                                                            onClick={() => handleDeleteQualification(qualification.id)}
                                                            className="bg-red-500 rounded-lg p-1 ml-3">
                                                            <TrashIcon className="h-5 w-5 text-neutral-200 " />

                                                        </button>
                                                    </td>

                                                </tr>
                                            ))

                                            }
                                            </table>
                                        </div>
                                    )}
                                </div>
                            </div>

                            {/* 3 new div */}
                            <div className="h-auto m-1 flex flex-col">
                                <div className="bg-white p-2 shadow-md   mt-2">

                                    <h2 className="text-lg font-semibold text-gray-800">Experience</h2>
                                    <div className={"flex justify-end items-end mb-1"}>
                                        <button onClick={openModalExperience} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                                            Add Experience
                                        </button>
                                    </div>

                                    {experienceLoading ? (
                                        <div className="flex flex-col items-center justify-center">
                                            <div className="loader"></div>
                                            <p>Loading...</p>
                                        </div>

                                    ):(
                                        <div>
                                            <table className={"min-w-full bg-white border border-gray-300 text-neutral-800"}>
                                                <thead>
                                                <tr>
                                                    <th className={"py-2 px-4 border-b whitespace-nowrap"}>
                                                        Country
                                                    </th>
                                                    <th className={"py-2 px-4 border-b whitespace-nowrap"}>
                                                        Duration
                                                    </th>
                                                    <th className={"py-2 px-4 border-b whitespace-nowrap"}>
                                                        Action
                                                    </th>
                                                </tr>
                                                </thead>
                                            {experience.map(experience => (
                                                <tr key={experience.id}>

                                                    <td className="py-2 px-4 border-b whitespace-nowrap">
                                                        {experience.country}
                                                    </td>
                                                    <td className="py-2 px-4 border-b whitespace-nowrap">
                                                        {experience.duration} Years
                                                    </td>
                                                    <td className="py-2 px-4 border-b whitespace-nowrap">
                                                        <button
                                                            onClick={() => handleDeleteExperience(experience.id)}
                                                            className="bg-red-500 rounded-lg p-1 ml-3">
                                                            <TrashIcon className="h-5 w-5 text-neutral-200 " />

                                                        </button>
                                                    </td>

                                                </tr>
                                            ))

                                            }
                                            </table>
                                        </div>
                                    )}
                                </div>
                            </div>


                            <div className="h-auto m-1 flex flex-col">
                                <div className="bg-white p-2 shadow-md   mt-2">

                                    <h2 className="text-lg font-semibold text-gray-800">Available Countries</h2>
                                    <div className={"flex justify-end items-end mb-1"}>
                                        <button onClick={openModalCountry} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                                            Change Visibility
                                        </button>
                                    </div>

                                    {countriesLoading ? (
                                        <div className="flex flex-col items-center justify-center">
                                            <div className="loader"></div>
                                            <p>Loading...</p>
                                        </div>

                                    ):(
                                        <div>
                                            <table className={"min-w-full bg-white border border-gray-300 text-neutral-800"}>
                                                <thead>
                                                    <tr>
                                                        <th className={"py-2 px-4 border-b whitespace-nowrap"}>
                                                            Country
                                                        </th>
                                                        <th className={"py-2 px-4 border-b whitespace-nowrap"}>
                                                            Action
                                                        </th>
                                                    </tr>
                                                </thead>
                                            {countriesList.map(countries => (


                                                    <tr key={countries.id}>

                                                        <td className="py-2 px-4 border-b whitespace-nowrap">
                                                            {countries.country}
                                                        </td>


                                                        <td className="py-2 px-4 border-b whitespace-nowrap">
                                                            <button
                                                                onClick={() => handleDeleteCountries(countries.id)}
                                                            className="bg-red-500 rounded-lg p-1 ml-3">
                                                            <TrashIcon className="h-5 w-5 text-neutral-200 " />

                                                        </button>
                                                        </td>
                                                    </tr>
                                            ))

                                            }
                                            </table>
                                        </div>
                                    )}
                                </div>
                            </div>



                        </div>



                    </div>



                </div>



            </div>

            {isOpenQualification && (
                <div className="fixed inset-0 flex items-center justify-center bg-gray-800  bg-opacity-50">
                    <div className="bg-white p-8 rounded-lg text-gray-800 w-5/12  px-8">
                        <div className="flex justify-between">
                        <h2 className="text-xl font-bold mb-4">Add new Qualification</h2>

                            <button onClick={closeModalQualification} className="bg-red-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded">
                                X
                            </button>
                        </div>
                        <form onSubmit={handleQualificationFormSubmit}>

                            <div>
                                <label htmlFor="qualification" className="block text-sm font-medium text-gray-600">
                                    Qualification
                                </label>
                                <input
                                    type="text"
                                    id="qualification"
                                    name="qualification"
                                    value={formData.qualification}
                                    onChange={handleInputChange}
                                    className="mt-1 p-2 w-full border rounded-md"
                                    placeholder="Type new qualification"
                                    required
                                />
                                <div className="flex justify-center items-center">



                                <button type="submit" className="mt-4 bg-blue-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded">
                                    Submit
                                </button>



                                </div>


                            </div>

                        </form>

                    </div>
                </div>
            )}


            {isOpenExperience && (
                <div className="fixed inset-0 flex items-center justify-center bg-gray-800  bg-opacity-50">
                    <div className="bg-white p-8 rounded-lg text-gray-800 w-5/12  px-8">
                        <div className="flex justify-between">
                            <h2 className="text-xl font-bold mb-4">Add new Experience</h2>

                            <button onClick={closeModalExperience} className="bg-red-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded">
                                X
                            </button>
                        </div>
                        <form onSubmit={handleExperienceFormSubmit}>

                                <div className="flex justify-between">
                                        <div>
                                            <label htmlFor="country" className="block text-sm font-medium text-gray-600">
                                                Country
                                            </label>
                                            <input
                                                type="text"
                                                id="country"
                                                name="country"
                                                value={formData.country}
                                                onChange={handleInputChange}
                                                className="mt-1 p-2 w-full border rounded-md"
                                                placeholder="Type new country"
                                                required
                                            />

                                        </div>


                                        <div>
                                            <label htmlFor="duration" className="block text-sm font-medium text-gray-600">
                                                Duration
                                            </label>
                                            <input
                                                type="number"
                                                id="duration"
                                                name="duration"
                                                value={formData.duration}
                                                onChange={handleInputChange}
                                                className="mt-1 p-2 w-full border rounded-md"
                                                placeholder="Type new duration"
                                                required
                                            />

                                        </div>
                                </div>


                                    <button type="submit" className="mt-4 bg-blue-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded">
                                        Submit
                                    </button>


                        </form>

                    </div>
                </div>
            )}


            {isOpenCountry && (
                <div className="fixed inset-0 flex items-center justify-center bg-gray-800  bg-opacity-50">
                    <div className="bg-white p-8 rounded-lg text-gray-800 w-5/12  px-8">
                        <div className="flex justify-between">
                            <h2 className="text-xl font-bold mb-4">Add new Visible Country</h2>

                            <button onClick={closeModalCountry} className="bg-red-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded">
                                X
                            </button>
                        </div>
                        <form onSubmit={handleCountryFormSubmit}>

                            <div>
                                <label htmlFor="visibleCountry" className="block text-sm font-medium text-gray-600">
                                    Country
                                </label>
                                <select
                                    id="visibleCountry"
                                    name="visibleCountry"
                                    value={formData.visibleCountry}
                                    onChange={handleInputChange}
                                    className="mt-1 p-2 w-full border rounded-md"
                                    placeholder="Type new qualification"
                                    required
                                >
                                    <option value="">Select a country</option>
                                    {countries.map((country, index) => (
                                        <option key={index} value={country}>
                                            {country}
                                        </option>
                                    ))}



                                </select>
                                <div className="flex justify-center items-center">



                                    <button type="submit" className="mt-4 bg-blue-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded">
                                        Submit
                                    </button>



                                </div>


                            </div>

                        </form>

                    </div>
                </div>
            )}


        </>
    );
};

export default ApplicantOtherDetails;
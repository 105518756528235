import React, { useEffect, useState } from 'react';

import '../css/style.css';
import Breadcrumb from '../components/breadcrumb';
import Footer from '../components/footer';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Textarea } from '@material-tailwind/react';
import MySQLUrl from "../DBConfig";
import {HomeIcon} from "@heroicons/react/24/solid";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const NewVacancy = () => {
    const navigate = useNavigate();
    const [User, setUser] = useState([]);
    const [currentTime, setCurrentTime] = useState(new Date());
    const formattedTime = currentTime.toLocaleTimeString();
    const [file, setFile] = useState(null);
    const [previewImage, setPreviewImage] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            const storedUser = localStorage.getItem('user');

            if (storedUser) {
                const parsedUser = JSON.parse(storedUser);
                setUser(parsedUser);


            }else{
                navigate('/login');
            }
        };

        // Initial data fetch
        fetchData();
    }, []);

    const [formData, setFormData] = useState({
        vacancy_name: '',
        company_home: '',
        country: '',
        age_range: '',
        description: '',
        price: '',
        required_experience: 0,
        employment_type: '',
        vacancyImage:file,
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        setFile(selectedFile);

        // Generate a preview image URL
        const previewImageUrl = URL.createObjectURL(selectedFile);
        setPreviewImage(previewImageUrl);
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();

        try {
            // Create FormData object
            const formDataToSend = new FormData();

            // Append other form data fields
            formDataToSend.append('vacancy_name', formData.vacancy_name);
            formDataToSend.append('company_home', formData.company_home);
            formDataToSend.append('country', formData.country);
            formDataToSend.append('age_range', formData.age_range);
            formDataToSend.append('description', formData.description);
            formDataToSend.append('price', formData.price);
            formDataToSend.append('required_experience', formData.required_experience);
            formDataToSend.append('employment_type', formData.employment_type);

            // Append image file
            formDataToSend.append('vacancyImage', file);

            // Send form data
            const response = await axios.post(`${MySQLUrl}/vacancy/new-vacancy`, formDataToSend);
            console.log('Response from the server:', response.data);
            toast.success('Vacancy created successfully');
            setFormData({
                vacancy_name: '',
                company_home: '',
                country: '',
                age_range: '',
                description: '',
                price: '',
                required_experience: 0,
                employment_type: '',
                image: null, // Clear the image
            });
            setPreviewImage(null);
            // Optionally, you can redirect the user to a new page or show a success message
        } catch (error) {
            toast.error('Error creating vacancy');
            console.error('Error submitting form:', error);
            // Handle error, show an error message, etc.
        }
    };


    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentTime(new Date());
        }, 1000); // Update every second

        // Cleanup the interval on component unmount
        return () => clearInterval(intervalId);
    }, []);

    const breadcrumbItems = [
        { label: <HomeIcon className="h-4 w-5" />, url: '/' },
        { label: 'Vacancy', url: '/vacancy' },
        { label: 'New Vacancy', url: '/new-vacancy' },
    ];



    return (
        <>
            <ToastContainer
                position="top-right" // Adjust position as needed
                autoClose={5000} // Close after 5 seconds
                hideProgressBar={false} // Show progress bar
                newestOnTop={false} // Show newest toast at the top
                closeOnClick // Close toast on click
                rtl={false} // Right to left
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />


            <div>
                <div className="mx-4 text-neutral-800 overflow-y-auto">
                    <div className="grid grid-cols-12 col-span-12 gap-4 xxl:col-span-9">

                        <div className="col-span-12 mt-0">
                            <div className="grid gap-2 grid-cols-1 lg:grid-cols-2">
                                <div className="mt-3">
                                    <Breadcrumb items={breadcrumbItems} />
                                </div>
                                <div className="mt-3 relative">
                                    {User.length === 0 ? (
                                        <div className="absolute right-0">
                                            Welcome, Loading...
                                        </div>
                                    ) : (
                                        <div className="absolute right-0 capitalize">
                                            Welcome, <strong>{User.username} </strong> | {formattedTime}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className="col-span-12 mt-0">
                            <div className="flex items-center">
                                <h2 className="mr-5 text-lg font-medium truncate ">New Vacancy</h2>
                            </div>
                        </div>



                        <div className="col-span-12 mt-1">
                            <div className="grid gap-2 grid-cols-1 lg:grid-cols-1">
                                <div className="bg-white p-4 shadow-lg rounded-lg h-[65vh] overflow-y-auto">

                                    <form onSubmit={handleFormSubmit}>
                                        {/* --1--*/}
                                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                            <div className="mb-4">
                                                <label htmlFor="vacancy_name" className="block text-sm font-medium text-gray-600">
                                                    Vacancy Name
                                                </label>
                                                <input
                                                    type="text"
                                                    id="vacancy_name"
                                                    name="vacancy_name"
                                                    placeholder="Eg: House Cleaner"
                                                    value={formData.vacancy_name}
                                                    onChange={handleInputChange}
                                                    className="mt-1 p-2 w-full border rounded-md"
                                                    required
                                                />
                                            </div>
                                            <div className="mb-4">
                                                <label htmlFor="company_home" className="block text-sm font-medium text-gray-600">
                                                    Company Home
                                                </label>
                                                <input
                                                    type="text"
                                                    id="company_home"
                                                    name="company_home"
                                                    placeholder="Eg: Tyre Company"
                                                    value={formData.company_home}
                                                    onChange={handleInputChange}
                                                    className="mt-1 p-2 w-full border rounded-md"
                                                />
                                            </div>
                                        </div>

                                        {/* --2--*/}
                                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                            <div className="mb-4">
                                                <label htmlFor="country" className="block text-sm font-medium text-gray-600">
                                                    Country
                                                </label>
                                                <input
                                                    type="text"
                                                    id="country"
                                                    name="country"
                                                    placeholder="Eg: Romania"
                                                    value={formData.country}
                                                    onChange={handleInputChange}
                                                    className="mt-1 p-2 w-full border rounded-md"
                                                />
                                            </div>
                                            <div className="mb-4">
                                                <label htmlFor="age_range" className="block text-sm font-medium text-gray-600">
                                                    Age Range
                                                </label>
                                                <input
                                                    type="text"
                                                    id="age_range"
                                                    name="age_range"
                                                    placeholder="Eg: 23-30"
                                                    value={formData.age_range}
                                                    onChange={handleInputChange}
                                                    className="mt-1 p-2 w-full border rounded-md"
                                                />
                                            </div>
                                        </div>

                                        {/* --3--*/}
                                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                            <div className="mb-4">
                                                <label htmlFor="description" className="block text-sm font-medium text-gray-600">
                                                    Description
                                                </label>
                                                <Textarea
                                                    className="mt-1 w-full border rounded-md"
                                                    id="description"
                                                    name="description"
                                                    placeholder="Eg: Vacancy Description"
                                                    value={formData.description}
                                                    onChange={handleInputChange}
                                                    required
                                                />
                                            </div>
                                            <div className="mb-4">
                                                <label htmlFor="price" className="block text-sm font-medium text-gray-600">
                                                    Price (Currency)
                                                </label>
                                                <input
                                                    type="text"
                                                    id="price"
                                                    name="price"
                                                    placeholder="eg: 1200"
                                                    value={formData.price}
                                                    onChange={handleInputChange}
                                                    className="mt-1 p-2 w-full border rounded-md"
                                                />
                                            </div>
                                        </div>

                                        {/* --3--*/}
                                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                            <div className="mb-4">
                                                <label htmlFor="required_experience" className="block text-sm font-medium text-gray-600">
                                                    Required Experience (Years)
                                                </label>
                                                <input
                                                    type="number"
                                                    id="required_experience"
                                                    name="required_experience"
                                                    placeholder="Eg: 2"
                                                    value={formData.required_experience}
                                                    onChange={handleInputChange}
                                                    className="mt-1 p-2 w-full border rounded-md"
                                                />
                                            </div>
                                            <div className="mb-4">
                                                <label htmlFor="employment_type" className="block text-sm font-medium text-gray-600">
                                                    Employment Type
                                                </label>
                                                <select
                                                    id="employment_type"
                                                    name="employment_type"
                                                    value={formData.employment_type}
                                                    onChange={handleInputChange}
                                                    className="mt-1 p-2 w-full border rounded-md"
                                                >
                                                    <option>Select employment type</option>
                                                    <option value={"Temporary"}>Temporary</option>
                                                    <option value={"Permanent"}>Permanent</option>
                                                </select>
                                            </div>
                                        </div>

                                        {/* --4--*/}

                                        <div className="mb-4 grid grid-cols-1 md:grid-cols-2 md:gap-4">
                                            {/* File Input */}
                                            <div className="mb-4 md:mb-0">
                                                <label htmlFor="image" className="block text-sm font-medium text-gray-600">
                                                    Upload Image
                                                </label>
                                                <input
                                                    type="file"
                                                    id="image"
                                                    name="image"
                                                    onChange={handleFileChange}
                                                    className="mt-1 p-2 w-full border rounded-md"
                                                    accept="image/*" // Allow only image files
                                                    required
                                                />
                                            </div>

                                            {/* Preview Image */}
                                            {previewImage && (
                                                <div>
                                                    <label className="block text-sm font-medium text-gray-600">
                                                        Preview Image
                                                    </label>
                                                    <img src={previewImage} alt="Preview" className="border border-black p-4 w-auto h-[35vh]" />
                                                </div>
                                            )}
                                        </div>
                                        <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded-md">
                                            Submit
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default NewVacancy;
import React, {useEffect, useState} from 'react';
import {toast, ToastContainer} from "react-toastify";
import Breadcrumb from "../components/breadcrumb";
import {ArrowPathIcon, HomeIcon, TrashIcon} from "@heroicons/react/24/solid";
import MySQLUrl from "../DBConfig";
import {useNavigate} from "react-router-dom";
import axios from "axios";

const NewWorkplace = () => {

    const navigate = useNavigate();
    const [User, setUser] = useState([]);
    const [WorkplaceData, setWorkplaceData] = useState([]);
    const [currentTime, setCurrentTime] = useState(new Date());
    const [accessToken, setAccessToken]=useState('');
    const [images, setImages] = useState([]);
    const [previewImages, setPreviewImages] = useState([]);

    const [formData, setFormData] = useState({
        images: [],
    });


    useEffect(() => {
        const fetchData = async () => {
            const storedUser = localStorage.getItem('user');

            if (storedUser) {
                const parsedUser = JSON.parse(storedUser);
                setUser(parsedUser);

                const accessToken = localStorage.getItem('accessToken');



                if(!accessToken){
                    localStorage.removeItem('smsToken');
                    localStorage.removeItem('smsUserData');
                    localStorage.removeItem('user');
                    navigate('/login');
                }

                setAccessToken(accessToken);

            }
        };

        // Initial data fetch
        fetchData();
    }, []);


    const handleImagesChange = (e) => {
        const selectedImages = e.target.files;
        const imagesArray = Array.from(selectedImages);

        setImages(imagesArray);

        // Generate preview image URLs
        const previewImageUrls = imagesArray.map(image => URL.createObjectURL(image));
        setPreviewImages(previewImageUrls);
    };


    const handleFormSubmit = async (e) => {
        e.preventDefault();

        try {
            // Create FormData object
            const formDataToSend = new FormData();


            // Append multiple images
            images.forEach((image, index) => {
                formDataToSend.append(`images`, image);
            });

            // console.log(formDataToSend);

            const config = {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            };

            // Send form data
            const response = await axios.post(`${MySQLUrl}/workplace/new`, formDataToSend, config);
            console.log('Response from the server:', response.data);
            toast.success('Workplace image added successfully',{ onClose: () => {
                    // Redirect to a suitable page after successful update
                    navigate('/workplace');
                }});
            // Clear form data and preview images after submission
            setFormData({

                images: [],
            });

            setImages([]);
            setPreviewImages([]);
        } catch (error) {
            toast.error('Error creating new workplace image/ images');
            console.error('Error submitting form:', error);
        }
    };







    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentTime(new Date());
        }, 1000); // Update every second

        // Cleanup the interval on component unmount
        return () => clearInterval(intervalId);
    }, []);


    const formattedTime = currentTime.toLocaleTimeString();






    const breadcrumbItems = [
        { label: <HomeIcon className="h-4 w-5" />, url: '/' },
        { label: 'Workplace', url: '/workplace' },
        { label: 'New', url: '/workplace/new' },
    ];

    const formatDate = (timestamp) => {
        const date = new Date(timestamp);
        return date.toLocaleString(); // You can customize the format using options
    };

    return (
        <>
            <ToastContainer
                position="top-right" // Adjust position as needed
                autoClose={1000} // Close after 2 seconds
                hideProgressBar={false} // Show progress bar
                newestOnTop={false} // Show newest toast at the top
                closeOnClick // Close toast on click
                rtl={false} // Right to left
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />

            <div>
                <div className="mx-4 text-neutral-800 overflow-y-auto">
                    <div className="grid grid-cols-12 col-span-12 gap-4 xxl:col-span-9">
                        <div className="col-span-12 mt-0">
                            <div className="grid gap-2 grid-cols-1 lg:grid-cols-2">
                                <div className="mt-3">
                                    <Breadcrumb items={breadcrumbItems} />
                                </div>
                                <div className="mt-3 relative">
                                    {User.length === 0 ? (
                                        <div className="absolute right-0">
                                            Welcome, Loading...
                                        </div>
                                    ) : (
                                        <div className="absolute right-0 capitalize">
                                            Welcome, <strong>{User.username} </strong> | {formattedTime}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="col-span-12 mt-0">
                            <div className="flex items-center">
                                <h2 className="mr-5 text-lg font-medium truncate">Workplace Gallery</h2>
                            </div>
                        </div>
                        <div className="col-span-12 mt-1">

                            <div className="grid gap-2 grid-cols-1 lg:grid-cols-1 mb-2">
                                <div className="bg-white p-4 shadow-lg rounded-lg">
                                    <div className="grid gap-2 grid-cols-1 lg:grid-cols-2 mb-2">
                                        <h1 className="font-bold text-base">New Workplace Image</h1>

                                    </div>

                                    <div className="bg-white p-4 shadow-lg rounded-lg h-[45vh] overflow-y-auto">
                                        <form onSubmit={handleFormSubmit}>


                                        <div className="mb-4 grid grid-cols-1 md:grid-cols-2 md:gap-4">
                                            <div className="mb-4 md:mb-0">
                                                <label htmlFor="images" className="block text-sm font-medium text-gray-600">
                                                    Upload Other Images (Note: You can select multiple images)
                                                </label>
                                                <input
                                                    type="file"
                                                    id="images"
                                                    name="images"
                                                    onChange={handleImagesChange}
                                                    className="mt-1 p-2 w-full border rounded-md"
                                                    accept="image/*"
                                                    multiple
                                                    required
                                                />
                                            </div>
                                            {previewImages.map((previewImageUrl, index) => (
                                                <div key={index}>
                                                    <label className="block text-sm font-medium text-gray-600">
                                                        Preview Image {index + 1}
                                                    </label>
                                                    <img src={previewImageUrl} alt={`Preview ${index}`} className="border border-black p-4 w-auto h-[35vh]" />
                                                </div>
                                            ))}
                                        </div>
                                        <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded-md">
                                            Submit
                                        </button>
                                    </form>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </>
    );
};

export default NewWorkplace;
import React, { useState, useEffect } from 'react';
import Marquee from 'react-fast-marquee';


import Oman from '../images/homepage/flags/Flag-Oman.webp';
import canada from '../images/homepage/flags/canada.webp';
import UAE from '../images/homepage/flags/UAE.webp';
import romania from '../images/homepage/flags/romania-flag.webp';
import jordan from '../images/homepage/flags/jordan-flag.webp';
import lebanon from '../images/homepage/flags/lebanon-flag.webp';
import kuwait from '../images/homepage/flags/kuwait.webp';
import saudi from '../images/homepage/flags/saudi-arabia-flag.webp';
import qatar from '../images/homepage/flags/qatar-flag.webp';
import sri from '../images/homepage/flags/sri-lanka.webp';



const countries = [


    { name: 'New York', timezone: 'Asia/Dubai', flag: UAE },
    { name: 'London', timezone: 'America/Toronto', flag: canada },
    { name: 'Oman', timezone: 'Asia/Muscat', flag: Oman },

    { name: 'Romania', timezone: 'Europe/Bucharest', flag: romania },
    { name: 'Jordan', timezone: 'Asia/Amman', flag: jordan },
    { name: 'Lebanon', timezone: 'Asia/Beirut', flag: lebanon },

    { name: 'Kuwait', timezone: 'Asia/Kuwait', flag: kuwait },
    { name: 'Saudi Arabia', timezone: 'Asia/Riyadh', flag: saudi },
    { name: 'Qatar', timezone: 'Asia/Qatar', flag: qatar },

    { name: 'Sri lanka', timezone: 'Asia/Colombo', flag: sri },

    // Add more countries as needed
];

const TimeBar = () => {
    const [times, setTimes] = useState(Array(countries.length).fill(''));

    useEffect(() => {
        const updateTimes = () => {
            const newTimes = countries.map((country) => {
                const timeZone = country.timezone;
                const options = { timeZone, hour12: false, hour: 'numeric', minute: 'numeric', second: 'numeric' };
                return new Date().toLocaleTimeString('en-US', options);
            });
            setTimes(newTimes);
        };

        const intervalId = setInterval(updateTimes, 1000);

        return () => clearInterval(intervalId);
    }, []);

    return (
        <div className="timezone-slider bg-neutral-800 text-white w-full">
            <div className="mx-auto">
                <Marquee>
                    {countries.map((country, index) => (
                        <div key={index} className="timezone-item px-4 flex items-center h-8" >
                            <div className="flag">
                                <img src={country.flag} alt={`${country.name} Flag`} style={{ width: '25px', height: '15px' }} />
                            </div>
                            <div className="text-neutral-200 text-sm font-bold ml-5">
                                {/*<p className="timezone-name">{country.name}</p>*/}
                                <p className="timezone-time">{times[index]}</p>
                            </div>
                        </div>
                    ))}
                </Marquee>
            </div>
        </div>

    );
};

export default TimeBar;
import React, { useCallback, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Breadcrumb from "../components/breadcrumb";
import {ReactTags} from "react-tag-autocomplete";
import {suggestions} from "../components/countries";
import {HomeIcon, TrashIcon} from "@heroicons/react/24/solid";
import {PlusIcon} from "@heroicons/react/20/solid";
import MySQLUrl from "../DBConfig";

const EditApplicant = () => {
    const { applicantId } = useParams();
    const [initialFormData, setInitialFormData] = useState({});
    const navigate = useNavigate();
    const [User, setUser] = useState([]);
    const [currentTime, setCurrentTime] = useState(new Date());
    const formattedTime = currentTime.toLocaleTimeString();
    const [loading, setLoading] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [formData, setFormData] = useState({
        teegee_ref:'',
        fullName: '',
        postApplied: '',
        salary: '',
        currency:'',
        contractPeriod: '',
        nationality: '',
        religion: '',
        DOB: '',
        birthPlace: '',
        livingTown: '',
        maritalStatus: '',
        noOfChildren: '',
        Weight: '',
        Height: '',
        Complexion: '',
        Age: '',
        english: '',
        arabic: '',
        Driving: '',
        Sewing: '',
        cooking: '',
        washing: '',
        cleaning: '',
        baby_sitting: '',
        passportNo: '',
        issuedPlace: '',
        IssuedDate: '',
        ExpiryDate: '',
    });

    const accessToken = localStorage.getItem('accessToken');
    // console.log(accessToken)



    if(!accessToken){
        localStorage.removeItem('smsToken');
        localStorage.removeItem('smsUserData');
        localStorage.removeItem('user');
        navigate('/login');
    }

    const config = {
        headers: {
            Authorization: `Bearer ${accessToken}`
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            const storedUser = localStorage.getItem('user');

            if (storedUser) {
                const parsedUser = JSON.parse(storedUser);
                setUser(parsedUser);
            }
        }
        fetchData();
    },[]);


    useEffect(() => {
        const fetchApplicantData = async () => {
            try {
                const response = await axios.get(`${MySQLUrl}/applicant/${applicantId}`, config);
                const { data } = response;
                const { monthly_Salary, DOB, IssuedDate, ExpiryDate } = data;

                const [salary, currency] = monthly_Salary.split(' ');

                console.log('salary curr:', currency);
                // Parse dates and format them
                const parsedDOB = new Date(DOB);
                const parsedIssuedDate = new Date(IssuedDate);
                const parsedExpiryDate = new Date(ExpiryDate);

                const formatDate = (date) => {
                    return date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);
                };

                const formattedDOB = formatDate(parsedDOB);
                const formattedIssuedDate = formatDate(parsedIssuedDate);
                const formattedExpiryDate = formatDate(parsedExpiryDate);

                // Save initial form data
                setInitialFormData({
                    ...data,
                    DOB: formattedDOB,
                    IssuedDate: formattedIssuedDate,
                    ExpiryDate: formattedExpiryDate,
                    salary: salary,
                    currency: currency,
                });

                // Set form data
                setFormData({
                    ...data,
                    DOB: formattedDOB,
                    IssuedDate: formattedIssuedDate,
                    ExpiryDate: formattedExpiryDate,
                    salary: salary,
                    currency: currency,
                });
            } catch (error) {
                console.error('Error fetching applicant data:', error);
                // Handle error, show an error message, etc.
            }
        };

        fetchApplicantData();
    }, [applicantId]);





    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const editedFields = identifyEditedFields();
        handleFormSubmit(editedFields);
    };


    // Function to identify edited fields
    const identifyEditedFields = () => {
        const editedFields = {};
        for (const key in formData) {
            if (formData[key] !== initialFormData[key]) {
                editedFields[key] = formData[key];
            }
        }
        return editedFields;
    };

    const handleFormSubmit = async () => {
        try {
            setLoading(true); // Set loading to true when form is submitted
            setDisabled(true); // Disable submit button
            // Identify edited fields
            const editedFields = {};
            for (const key in formData) {
                if (formData[key] !== initialFormData[key]) {
                    editedFields[key] = formData[key];
                }
            }

            // Check if there are edited fields
            if (Object.keys(editedFields).length === 0) {
                console.log('No fields edited');
                return;
            }

            // Send PUT request to update the applicant data
            const response = await axios.put(`${MySQLUrl}/applicant/edit/${applicantId}`, editedFields, config);
            console.log('Response from the server:', response.data);
            toast.success(response.data.message, {
                onClose: () => {
                    // Redirect to a suitable page after successful update
                    navigate('/applicants');
                }
            });
        } catch (error) {
            setLoading(false); // Set loading to true when form is submitted
            setDisabled(false); // Disable submit button
            toast.error('Error updating applicant');
            console.error('Error submitting form:', error);
            // Handle error, show an error message, etc.
        }
    };





    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentTime(new Date());
        }, 1000); // Update every second

        // Cleanup the interval on component unmount
        return () => clearInterval(intervalId);
    }, []);

    const goToOtherDetails = (applicantId) => {
        navigate(`/applicant/details/${applicantId}`);
        console.log('Button clicked!');
    };



    const breadcrumbItems = [
        { label: <HomeIcon className="h-4 w-5" />, url: '/' },
        { label: 'Applicants', url: '/applicant' },
        { label: 'Edit Applicant', url: '/applicant/edit' },
    ];

    return (
        <div>
            <ToastContainer
                position="top-right" // Adjust position as needed
                autoClose={2000} // Close after 5 seconds
                hideProgressBar={false} // Show progress bar
                newestOnTop={false} // Show newest toast at the top
                closeOnClick // Close toast on click
                rtl={false} // Right to left
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />


            <div>
                <div className="mx-4 text-neutral-800 overflow-y-auto">
                    <div className="grid grid-cols-12 col-span-12 gap-4 xxl:col-span-9">

                        <div className="col-span-12 mt-0">
                            <div className="grid gap-2 grid-cols-1 lg:grid-cols-2">
                                <div className="mt-3">
                                    <Breadcrumb items={breadcrumbItems} />
                                </div>
                                <div className="mt-3 relative">
                                    {User.length === 0 ? (
                                        <div className="absolute right-0">
                                            Welcome, Loading...
                                        </div>
                                    ) : (
                                        <div className="absolute right-0 capitalize">
                                            Welcome, <strong>{User.username} </strong> | {formattedTime}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className="col-span-12 mt-0 flex">
                            <div className="flex items-center">
                                <h2 className="mr-5 text-lg font-medium truncate ">Edit Applicants</h2>
                            </div>
                            <button
                                onClick={() => goToOtherDetails(applicantId)}
                                className="flex items-center bg-green-700 hover:accent-green-500 rounded-lg py-1 px-2 mr-3">
                                <h2 className="mr-5 text-md font-medium truncate text-neutral-200">Edit Other Details</h2>
                            </button>

                        </div>



                        <div className="col-span-12 mt-1">
                            <div className="grid gap-2 grid-cols-1 lg:grid-cols-1">
                                <div className="bg-white p-4 shadow-lg rounded-lg h-[65vh] overflow-y-auto">

                                    {/* Form */}
                                    <form onSubmit={handleSubmit}>

                                        {/* 1*/}

                                        <div className="mb-4 grid grid-cols-2 gap-4">
                                            <div>
                                                <label htmlFor="fullName" className="block text-sm font-medium text-gray-600">
                                                    Full Name
                                                </label>
                                                <input
                                                    type="text"
                                                    id="fullName"
                                                    name="fullName"
                                                    value={formData.fullName}
                                                    placeholder="Eg: John Doe"
                                                    onChange={handleInputChange}
                                                    className="mt-1 p-2 w-full border rounded-md focus:outline-none focus:ring focus:border-blue-300"
                                                    required
                                                />
                                                {/*{formData.fullName.length === 0 && (*/}
                                                {/*    <p className="text-red-500 text-xs italic">Please fill out this field.</p>*/}
                                                {/*)}*/}
                                                {/*{formData.fullName.length >= 10 && !formData.fullName.match(/[^a-zA-Z]+$/) && (*/}
                                                {/*    <p className="text-green-500 text-xs italic">Looks Good</p>*/}
                                                {/*)}*/}
                                                {/*{formData.fullName.length > 0 && formData.fullName.length < 10 && (*/}
                                                {/*    <p className="text-red-500 text-xs italic">Full name must be at least 10 characters long.</p>*/}
                                                {/*)}*/}
                                                {/*{formData.fullName.length >= 10 && formData.fullName.match(/[^a-zA-Z]+$/) && (*/}
                                                {/*    <p className="text-red-500 text-xs italic">Please use only alphabets.</p>*/}
                                                {/*)}*/}


                                            </div>

                                            <div className="mb-1 grid grid-cols-2 gap-4">

                                                <div>
                                                    <label htmlFor="postApplied" className="block text-sm font-medium text-gray-600">
                                                        Post Applied
                                                    </label>
                                                    <input
                                                        type="text"
                                                        id="postApplied"
                                                        name="postApplied"
                                                        value={formData.postApplied}
                                                        onChange={handleInputChange}
                                                        className="mt-1 p-2 w-full border rounded-md"
                                                        placeholder="Eg: House Cleaner"
                                                        required
                                                    />
                                                    {/*{formData.postApplied.length === 0 && (*/}
                                                    {/*    <p className="text-red-500 text-xs italic">Please fill out this field.</p>*/}
                                                    {/*)}*/}
                                                    {/*{formData.postApplied.length >= 5 && !formData.postApplied.match(/[^a-zA-Z]+$/) && (*/}
                                                    {/*    <p className="text-green-500 text-xs italic">Looks Good</p>*/}
                                                    {/*)}*/}
                                                    {/*{formData.postApplied.length > 0 && formData.postApplied.length < 5 && (*/}
                                                    {/*    <p className="text-red-500 text-xs italic">Must be at least 5 characters long.</p>*/}
                                                    {/*)}*/}
                                                    {/*{formData.postApplied.length >= 5 && formData.postApplied.match(/[^a-zA-Z]+$/) && (*/}
                                                    {/*    <p className="text-red-500 text-xs italic">Please use only alphabets.</p>*/}
                                                    {/*)}*/}

                                                </div>


                                                <div>
                                                    <label htmlFor="teegee_ref" className="block text-sm font-medium text-gray-600">
                                                        Reference No
                                                    </label>
                                                    <input
                                                        type="text"
                                                        id="teegee_ref"
                                                        name="teegee_ref"
                                                        value={formData.teegee_ref}
                                                        placeholder="Eg: #SA123"
                                                        onChange={handleInputChange}
                                                        className="mt-1 p-2 w-full border rounded-md focus:outline-none focus:ring focus:border-blue-300"
                                                        required
                                                    />
                                                    {/*{formData.teegee_ref.length === 0 && (*/}
                                                    {/*    <p className="text-red-500 text-xs italic">Please fill out this field.</p>*/}
                                                    {/*)}*/}
                                                    {/*{formData.teegee_ref.length >= 4 &&  (*/}
                                                    {/*    <p className="text-green-500 text-xs italic">Looks Good</p>*/}
                                                    {/*)}*/}



                                                </div>

                                            </div>
                                        </div>


                                        <div className="mb-4 grid grid-cols-2 gap-4">

                                            <div className="mb-4 grid grid-cols-1 gap-4">
                                                <div className="flex flex-box">
                                                    <div className="flex-grow mr-2">
                                                        <label htmlFor="salary" className="block text-sm font-medium text-gray-600">
                                                            Monthly Salary
                                                        </label>
                                                        <input
                                                            type="text"
                                                            id="salary"
                                                            name="salary"
                                                            value={formData.salary}
                                                            onChange={handleInputChange}
                                                            placeholder="Eg: 500"
                                                            className="mt-1 p-2 w-full border rounded-md focus:outline-none focus:ring focus:border-blue-300"
                                                            required
                                                        />
                                                        {/*{formData.salary.length === 0 && (*/}
                                                        {/*    <p className="text-red-500 text-xs italic">Please fill out this field.</p>*/}
                                                        {/*)}*/}
                                                        {/*{formData.salary.match(/[^0-9]/) && (*/}
                                                        {/*    <p className="text-red-500 text-xs italic">Please use only numerical characters.</p>*/}
                                                        {/*)}*/}
                                                        {/*{formData.salary.length >= 2 && !formData.salary.match(/[^0-9]/) && (*/}
                                                        {/*    <p className="text-green-500 text-xs italic">Looks Good</p>*/}
                                                        {/*)}*/}



                                                    </div>


                                                    <div className="">
                                                        <label htmlFor="currency" className="block text-sm font-medium text-gray-600">
                                                            Currency
                                                        </label>
                                                        <select
                                                            id="currency"
                                                            name="currency"
                                                            value={formData.currency}
                                                            onChange={handleInputChange}
                                                            required
                                                            className="mt-1 p-2 w-full border rounded-md"
                                                        >
                                                            <option value="" disabled selected>Select Currency</option>
                                                            <option value="USD">USD</option>
                                                            <option value="EUR">EUR</option>
                                                            <option value="GBP">GBP</option>
                                                            <option value="AED">AED</option>
                                                            <option value="SAR">SAR</option>
                                                            <option value="QAR">QAR</option>
                                                            <option value="KWD">KWD</option>
                                                            <option value="OMR">OMR</option>
                                                            <option value="LKR">LKR</option>
                                                            {/* Add more currency options as needed */}
                                                        </select>
                                                    </div>



                                                </div>


                                            </div>


                                            <div className="mb-4 grid grid-cols-2 gap-4">

                                                <div>
                                                    <label htmlFor="contractPeriod" className="block text-sm font-medium text-gray-600">
                                                        Contract Period
                                                    </label>
                                                    <select
                                                        id="contractPeriod"
                                                        name="contractPeriod"
                                                        value={formData.contractPeriod}
                                                        onChange={handleInputChange}
                                                        className="mt-1 p-2 w-full border rounded-md"
                                                        required
                                                    >
                                                        <option >Select Contract Period</option>
                                                        <option value={"1"}>One Year / 1 Year</option>
                                                        <option value={"2"}>Two Years / 2 Years</option>
                                                        <option value={"3"}>Three Years / 3 Years</option>
                                                        <option value={"4"}>Four Years / 4 Years</option>
                                                        <option value={"5"}>Five Years / 5 Years</option>
                                                        <option value={"6"}>Six Years / 6 Years</option>

                                                    </select>

                                                    {/*{formData.contractPeriod === 0 && (*/}
                                                    {/*    <p className="text-red-500 text-xs italic">Please fill out this field.</p>*/}
                                                    {/*)}*/}
                                                </div>

                                                <div>
                                                    <label htmlFor="gender" className="block text-sm font-medium text-gray-600">
                                                        Gender
                                                    </label>
                                                    <select
                                                        id="gender"
                                                        name="gender"
                                                        value={formData.gender}
                                                        onChange={handleInputChange}
                                                        className="mt-1 p-2 w-full border rounded-md"
                                                        required
                                                    >
                                                        <option >Select Gender</option>
                                                        <option value={"male"}>Male</option>
                                                        <option value={"female"}>Female</option>

                                                    </select>

                                                </div>
                                            </div>
                                        </div>



                                        {/* 3 */}

                                        <div className="mb-4 grid grid-cols-2 gap-4">
                                            <div className="mb-4 grid grid-cols-2 gap-4">
                                                <div>
                                                    <label htmlFor="nationality" className="block text-sm font-medium text-gray-600">
                                                        Nationality
                                                    </label>
                                                    <select
                                                        id="nationality"
                                                        name="nationality"
                                                        value={formData.nationality}
                                                        onChange={handleInputChange}
                                                        className="mt-1 p-2 w-full border rounded-md"
                                                        required
                                                    >
                                                        <option value="">Select Country</option>
                                                        <option value="Sri Lankan">Sri Lankan</option>
                                                    </select>

                                                </div>
                                                <div>
                                                    <label htmlFor="religion" className="block text-sm font-medium text-gray-600">
                                                        Religion
                                                    </label>
                                                    <select
                                                        id="religion"
                                                        name="religion"
                                                        value={formData.religion}
                                                        onChange={handleInputChange}
                                                        className="mt-1 p-2 w-full border rounded-md"
                                                        required
                                                    >
                                                        <option value="">Select Religion</option>
                                                        <option value="Christianity">Christianity</option>
                                                        <option value="Islam">Islam</option>
                                                        <option value="Hinduism">Hinduism</option>
                                                        <option value="Buddhism">Buddhism</option>

                                                        {/* Add more options as needed */}
                                                    </select>
                                                </div>

                                            </div>

                                            <div className="mb-4 grid grid-cols-2 gap-4">
                                                <div>
                                                    <label htmlFor="DOB" className="block text-sm font-medium text-gray-600">
                                                        DOB
                                                    </label>
                                                    <input
                                                        type="date"
                                                        id="DOB"
                                                        name="DOB"
                                                        value={formData.DOB}
                                                        onChange={handleInputChange}
                                                        className="mt-1 p-2 w-full border rounded-md"
                                                        required
                                                    />


                                                </div>

                                                <div>
                                                    <label htmlFor="birthPlace" className="block text-sm font-medium text-gray-600">
                                                        Birth Place
                                                    </label>
                                                    <input
                                                        type="text"
                                                        id="birthPlace"
                                                        name="birthPlace"
                                                        value={formData.birthPlace}
                                                        onChange={handleInputChange}
                                                        className="mt-1 p-2 w-full border rounded-md"
                                                        placeholder="Eg: Kurunegala"
                                                        required
                                                    />
                                                    {/*{formData.birthPlace.length === 0 && (*/}
                                                    {/*    <p className="text-red-500 text-xs italic">Please fill out this field.</p>*/}
                                                    {/*)}*/}
                                                    {/*{formData.birthPlace.length >= 4 && (*/}
                                                    {/*    <p className="text-green-500 text-xs italic">Looks Good</p>*/}
                                                    {/*)}*/}

                                                </div>
                                            </div>
                                        </div>




                                        {/* 4 */}

                                        <div className="mb-4 grid grid-cols-1 md:grid-cols-2 gap-4">
                                            <div className="mb-4 grid grid-cols-1 md:grid-cols-2 gap-4">
                                                <div>
                                                    <label htmlFor="livingTown" className="block text-sm font-medium text-gray-600">
                                                        Living Town
                                                    </label>
                                                    <input
                                                        type="text"
                                                        id="livingTown"
                                                        name="livingTown"
                                                        value={formData.livingTown}
                                                        onChange={handleInputChange}
                                                        placeholder="Eg: Kurunegala"
                                                        className="mt-1 p-2 w-full border rounded-md"
                                                        required
                                                    />

                                                    {/*{formData.livingTown.length === 0 && (*/}
                                                    {/*    <p className="text-red-500 text-xs italic">Please fill out this field.</p>*/}
                                                    {/*)}*/}
                                                    {/*{formData.livingTown.length >= 4 &&  (*/}
                                                    {/*    <p className="text-green-500 text-xs italic">Looks Good</p>*/}
                                                    {/*)}*/}
                                                </div>
                                                <div>
                                                    <label htmlFor="maritalStatus" className="block text-sm font-medium text-gray-600">
                                                        Marital Status
                                                    </label>
                                                    <select
                                                        id="maritalStatus"
                                                        name="maritalStatus"
                                                        value={formData.maritalStatus}
                                                        onChange={handleInputChange}
                                                        className="mt-1 p-2 w-full border rounded-md"
                                                        required
                                                    >
                                                        <option>Select Marital Status</option>
                                                        <option value={"single"}>Single</option>
                                                        <option value={"married"}>Married</option>
                                                        <option value={"divorced"}>Divorced</option>
                                                        <option value={"widowed"}>Widowed</option>

                                                    </select>
                                                </div>
                                            </div>

                                            <div className="mb-4 grid grid-cols-1 md:grid-cols-2 gap-4">
                                                <div>
                                                    <label htmlFor="noOfChildren" className="block text-sm font-medium text-gray-600">
                                                        No Of Children
                                                    </label>
                                                    <input
                                                        type="number"
                                                        id="noOfChildren"
                                                        name="noOfChildren"
                                                        value={formData.noOfChildren}
                                                        placeholder="Eg: 1"
                                                        onChange={handleInputChange}
                                                        className="mt-1 p-2 w-full border rounded-md"
                                                        required
                                                    />

                                                    {/*{formData.noOfChildren.length === 0 && (*/}
                                                    {/*    <p className="text-red-500 text-xs italic">Please fill out this field.</p>*/}
                                                    {/*)}*/}
                                                    {/*{formData.noOfChildren.length >= 1 &&  (*/}
                                                    {/*    <p className="text-green-500 text-xs italic">Looks Good</p>*/}
                                                    {/*)}*/}
                                                </div>
                                                <div>
                                                    <label htmlFor="Weight" className="block text-sm font-medium text-gray-600">
                                                        Weight (Kg)
                                                    </label>
                                                    <input
                                                        type="number"
                                                        id="Weight"
                                                        name="Weight"
                                                        placeholder="Eg: 75"
                                                        value={formData.Weight}
                                                        onChange={handleInputChange}
                                                        className="mt-1 p-2 w-full border rounded-md"
                                                        required
                                                    />
                                                    {/*{formData.Weight.length === 0 && (*/}
                                                    {/*    <p className="text-red-500 text-xs italic">Please fill out this field.</p>*/}
                                                    {/*)}*/}
                                                    {/*{formData.Weight.length >= 1 &&  (*/}
                                                    {/*    <p className="text-green-500 text-xs italic">Looks Good</p>*/}
                                                    {/*)}*/}
                                                </div>
                                            </div>
                                        </div>

                                        {/* 5 */}

                                        <div className="mb-4 grid grid-cols-1 md:grid-cols-2 gap-4">
                                            <div className="mb-4 grid grid-cols-1 md:grid-cols-2 gap-4">
                                                <div>
                                                    <label htmlFor="Height" className="block text-sm font-medium text-gray-600">
                                                        Height (cm)
                                                    </label>
                                                    <input
                                                        type="text"
                                                        id="Height"
                                                        name="Height"
                                                        placeholder="Eg: 165"
                                                        value={formData.Height}
                                                        onChange={handleInputChange}
                                                        className="mt-1 p-2 w-full border rounded-md"
                                                        required
                                                    />
                                                    {/*{formData.Height === 0 && (*/}
                                                    {/*    <p className="text-red-500 text-xs italic">Please fill out this field.</p>*/}
                                                    {/*)}*/}
                                                    {/*{formData.Height >= 1 &&  (*/}
                                                    {/*    <p className="text-green-500 text-xs italic">Looks Good</p>*/}
                                                    {/*)}*/}
                                                </div>
                                                <div>
                                                    <label htmlFor="Complexion" className="block text-sm font-medium text-gray-600">
                                                        Complexion
                                                    </label>
                                                    <input
                                                        type="text"
                                                        id="Complexion"
                                                        name="Complexion"
                                                        placeholder="Eg: Fair"
                                                        value={formData.Complexion}
                                                        onChange={handleInputChange}
                                                        className="mt-1 p-2 w-full border rounded-md"
                                                        required
                                                    />

                                                    {/*{formData.Complexion.length === 0 && (*/}
                                                    {/*    <p className="text-red-500 text-xs italic">Please fill out this field.</p>*/}
                                                    {/*)}*/}
                                                    {/*{formData.Complexion.length >= 3 &&  (*/}
                                                    {/*    <p className="text-green-500 text-xs italic">Looks Good</p>*/}
                                                    {/*)}*/}
                                                </div>
                                            </div>

                                            <div className="mb-4 grid grid-cols-1 md:grid-cols-2 gap-4">
                                                <div>
                                                    <label htmlFor="Age" className="block text-sm font-medium text-gray-600">
                                                        Age
                                                    </label>
                                                    <select
                                                        id="Age"
                                                        name="Age"
                                                        value={formData.Age}
                                                        onChange={handleInputChange}
                                                        className="mt-1 p-2 w-full border rounded-md"
                                                        required
                                                    >
                                                        <option value="">Select Age</option>
                                                        {/* Loop through ages from 1 to 85 */}
                                                        {Array.from({ length: 65 }, (_, i) => i + 17).map((Age) => (
                                                            <option key={Age} value={Age}>
                                                                {Age}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>

                                                <div>
                                                    <label htmlFor="english" className="block text-sm font-medium text-gray-600">
                                                        English
                                                    </label>
                                                    <select
                                                        id="english"
                                                        name="english"
                                                        value={formData.english}
                                                        onChange={handleInputChange}
                                                        className="mt-1 p-2 w-full border rounded-md"

                                                    >
                                                        <option value="">Select proficiency</option>
                                                        <option value="excellent">Excellent</option>
                                                        <option value="good">Good</option>
                                                        <option value="fair">Fair</option>
                                                        <option value="poor">Poor</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>



                                        {/* 4 */}

                                        <div className="mb-4 grid grid-cols-1 md:grid-cols-2 gap-4">
                                            <div className="mb-4 grid grid-cols-1 md:grid-cols-2 gap-4">
                                                <div>
                                                    <label htmlFor="arabic" className="block text-sm font-medium text-gray-600">
                                                        Arabic
                                                    </label>
                                                    <select
                                                        id="arabic"
                                                        name="arabic"
                                                        value={formData.arabic}
                                                        onChange={handleInputChange}
                                                        className="mt-1 p-2 w-full border rounded-md"

                                                    >
                                                        <option value="">Select proficiency</option>
                                                        <option value="excellent">Excellent</option>
                                                        <option value="good">Good</option>
                                                        <option value="fair">Fair</option>
                                                        <option value="poor">Poor</option>
                                                    </select>
                                                </div>
                                                <div>
                                                    <label htmlFor="Driving" className="block text-sm font-medium text-gray-600">
                                                        Driving
                                                    </label>
                                                    <select
                                                        id="Driving"
                                                        name="Driving"
                                                        value={formData.Driving}
                                                        onChange={handleInputChange}
                                                        className="mt-1 p-2 w-full border rounded-md"

                                                    >
                                                        <option value="">Select Ability</option>
                                                        <option value="can">Can</option>
                                                        <option value="can't">Can't</option>

                                                    </select>
                                                </div>
                                            </div>

                                            <div className="mb-4 grid grid-cols-1 md:grid-cols-2 gap-4">
                                                <div>
                                                    <label htmlFor="Sewing" className="block text-sm font-medium text-gray-600">
                                                        Sewing
                                                    </label>
                                                    <select
                                                        id="Sewing"
                                                        name="Sewing"
                                                        value={formData.Sewing}
                                                        onChange={handleInputChange}
                                                        className="mt-1 p-2 w-full border rounded-md"

                                                    >
                                                        <option value="">Select Ability</option>
                                                        <option value="can">Can</option>
                                                        <option value="can't">Can't</option>
                                                    </select>
                                                </div>
                                                <div>
                                                    <label htmlFor="cooking" className="block text-sm font-medium text-gray-600">
                                                        Cooking
                                                    </label>
                                                    <select
                                                        id="cooking"
                                                        name="cooking"
                                                        value={formData.cooking}
                                                        onChange={handleInputChange}
                                                        className="mt-1 p-2 w-full border rounded-md"

                                                    >
                                                        <option value="">Select Ability</option>
                                                        <option value="can">Can</option>
                                                        <option value="can't">Can't</option>
                                                    </select>



                                                </div>
                                            </div>
                                        </div>


                                        {/* 7 */}

                                        <div className="mb-4 grid grid-cols-1 md:grid-cols-2 gap-4">
                                            <div className="mb-4 grid grid-cols-1 md:grid-cols-2 gap-4">
                                                <div>
                                                    <label htmlFor="washing" className="block text-sm font-medium text-gray-600">
                                                        Washing
                                                    </label>
                                                    <select
                                                        id="washing"
                                                        name="washing"
                                                        value={formData.washing}
                                                        onChange={handleInputChange}
                                                        className="mt-1 p-2 w-full border rounded-md"

                                                    >
                                                        <option value="">Select Ability</option>
                                                        <option value="can">Can</option>
                                                        <option value="can't">Can't</option>
                                                    </select>

                                                </div>
                                                <div>
                                                    <label htmlFor="cleaning" className="block text-sm font-medium text-gray-600">
                                                        Cleaning
                                                    </label>
                                                    <select
                                                        id="cleaning"
                                                        name="cleaning"
                                                        value={formData.cleaning}
                                                        onChange={handleInputChange}
                                                        className="mt-1 p-2 w-full border rounded-md"

                                                    >
                                                        <option value="">Select Ability</option>
                                                        <option value="can">Can</option>
                                                        <option value="can't">Can't</option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div className="mb-4 grid grid-cols-1 md:grid-cols-2 gap-4">
                                                <div>
                                                    <label htmlFor="baby_sitting" className="block text-sm font-medium text-gray-600">
                                                        Baby Sitting
                                                    </label>
                                                    <select
                                                        id="baby_sitting"
                                                        name="baby_sitting"
                                                        value={formData.baby_sitting}
                                                        onChange={handleInputChange}
                                                        className="mt-1 p-2 w-full border rounded-md"

                                                    >
                                                        <option value="">Select Ability</option>
                                                        <option value="can">Can</option>
                                                        <option value="can't">Can't</option>
                                                    </select>

                                                </div>

                                            </div>
                                        </div>



                                        {/* 8 */}

                                        <div className="mb-4 grid grid-cols-1 md:grid-cols-2 gap-4">
                                            <div className="mb-4 grid grid-cols-1 md:grid-cols-2 gap-4">
                                                <div>
                                                    <label htmlFor="passportNo" className="block text-sm font-medium text-gray-600">
                                                        Passport Number
                                                    </label>
                                                    <input
                                                        type="text"
                                                        id="passportNo"
                                                        name="passportNo"
                                                        placeholder="Eg: N1234567"
                                                        value={formData.passportNo}
                                                        onChange={handleInputChange}
                                                        className="mt-1 p-2 w-full border rounded-md"
                                                        required
                                                    />
                                                    {/*{formData.passportNo.length === 0 && (*/}
                                                    {/*    <p className="text-red-500 text-xs italic">Please fill out this field.</p>*/}
                                                    {/*)}*/}
                                                    {/*{formData.passportNo.length >= 6 &&  (*/}
                                                    {/*    <p className="text-green-500 text-xs italic">Looks Good</p>*/}
                                                    {/*)}*/}
                                                </div>
                                                <div>
                                                    <label htmlFor="issuedPlace" className="block text-sm font-medium text-gray-600">
                                                        Issued Place
                                                    </label>
                                                    <input
                                                        type="text"
                                                        id="issuedPlace"
                                                        name="issuedPlace"
                                                        placeholder="Eg: Colombo"
                                                        value={formData.issuedPlace}
                                                        onChange={handleInputChange}
                                                        className="mt-1 p-2 w-full border rounded-md"
                                                        required
                                                    />
                                                    {/*{formData.issuedPlace.length === 0 && (*/}
                                                    {/*    <p className="text-red-500 text-xs italic">Please fill out this field.</p>*/}
                                                    {/*)}*/}
                                                    {/*{formData.issuedPlace.length >= 4 &&  (*/}
                                                    {/*    <p className="text-green-500 text-xs italic">Looks Good</p>*/}
                                                    {/*)}*/}
                                                </div>
                                            </div>

                                            <div className="mb-4 grid grid-cols-1 md:grid-cols-2 gap-4">
                                                <div>
                                                    <label htmlFor="IssuedDate" className="block text-sm font-medium text-gray-600">
                                                        Issued Date
                                                    </label>
                                                    <input
                                                        type="date"
                                                        id="IssuedDate"
                                                        name="IssuedDate"
                                                        value={formData.IssuedDate}
                                                        onChange={handleInputChange}
                                                        className="mt-1 p-2 w-full border rounded-md"
                                                        required
                                                    />
                                                    {/*{formData.IssuedDate === 0 && (*/}
                                                    {/*    <p className="text-red-500 text-xs italic">Please fill out this field.</p>*/}
                                                    {/*)}*/}
                                                </div>

                                                <div>
                                                    <label htmlFor="ExpiryDate" className="block text-sm font-medium text-gray-600">
                                                        Expiry Date
                                                    </label>
                                                    <input
                                                        type="date"
                                                        id="ExpiryDate"
                                                        name="ExpiryDate"
                                                        value={formData.ExpiryDate}
                                                        onChange={handleInputChange}
                                                        className="mt-1 p-2 w-full border rounded-md"
                                                        required
                                                    />
                                                    {/*{formData.ExpiryDate === 0 && (*/}
                                                    {/*    <p className="text-red-500 text-xs italic">Please fill out this field.</p>*/}
                                                    {/*)}*/}
                                                </div>

                                            </div>
                                        </div>



                                        <button
                                            type="submit"
                                            className="bg-blue-500 text-white px-4 py-2 rounded-md"
                                            disabled={disabled} // Disable button when disabled state is true
                                        >
                                            {loading ? (
                                                <span className="flex items-center">
                            <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 4.418 3.582 8 8 8v-4c-2.481 0-4.736-.897-6.472-2.391l2.472-2.472 1.414 1.414-3.5 3.5zM20 12a8 8 0 11-16 0 8 8 0 0116 0z"></path>
                            </svg>
                            Submitting...
                        </span>
                                            ) : (
                                                'Submit'
                                            )}
                                        </button>


                                    </form>
                                    {/* End of Form */}

                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditApplicant;
